import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NestedPageHeader, NestedPageHeaderActions, NestedPageHeaderTitles } from '../../shared/NestedPageHeader'
import { TabsContainer } from '../../shared/tabs/TabSharedComponents'
import { CapitalizedText, GrayText } from '../../shared/TextComponents'
import { AdminContentWrapper } from '../adminStyles'
import { Vendor } from '../../../utils/vendors'
import { AwsIntegrations } from './aws/AwsIntegrations'
import { AzureIntegrations } from './azure/AzureIntegrations'
import { VendorTabs } from '../../shared/tabs/VendorTabs'
import { GcpIntegrations } from './gcp/GcpIntegrations'
import { Button, ButtonSize } from '../../shared/buttons/Button'
import { useHistory } from 'react-router-dom'
import { ModalContext } from '../../../state/context/ModalContext'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { AwsMasterAccount } from '../../../api/admin/integrations/aws'
import { AzureIntegration } from '../../../api/admin/integrations/azure'
import { GcpIntegration } from '../../../api/admin/integrations/gcp'
import { getAuthInfo } from '../../../api/auth'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { SpotterDocumentationLink } from '../../login/Shared'
import { firstLogin } from '../../../state/storage'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import awsIcon from '../../../assets/svg/vendors/aws.svg'
import azureIcon from '../../../assets/svg/vendors/azure.svg'
import gcpIcon from '../../../assets/svg/vendors/gcp.svg'
import { hasIntegrationErrors } from '../../../api/settings/profile'
import { PingType } from '../../shared/tabs/Tab'

interface AdminIntegrationsProps {
  selectedVendor: Vendor
}

export const AdminIntegrations = ({ selectedVendor }: AdminIntegrationsProps) => {
  const { t } = useTranslation()
  const { authInfo, setAuthInfo, integrationStates } = useContext(UserInfoContext)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState<Vendor>(selectedVendor)
  const [awsIntegrations, setAwsIntegrations] = useState<AwsMasterAccount[]>([])
  const [azureIntegrations, setAzureIntegrations] = useState<AzureIntegration[]>([])
  const [gcpIntegrations, setGcpIntegrations] = useState<GcpIntegration[]>([])
  const hasAddedIntegration = awsIntegrations.length > 0 || azureIntegrations.length > 0 || gcpIntegrations.length > 0
  const noIntegrations = !authInfo.azureIntegration && !authInfo.awsIntegration && !authInfo.gcpIntegration
  const pingVendors = [
    hasIntegrationErrors(integrationStates.aws) && {
      vendor: Vendor.AWS,
      type: PingType.ERROR
    },
    hasIntegrationErrors(integrationStates.azure) && {
      vendor: Vendor.AZURE,
      type: PingType.ERROR
    },
    hasIntegrationErrors(integrationStates.gcp) && {
      vendor: Vendor.GCP,
      type: PingType.ERROR
    }
  ].filter(Boolean) as { vendor: Vendor; type: PingType }[]

  useEffect(() => {
    hasAddedIntegration &&
      noIntegrations &&
      getAuthInfo(createCancelToken().token)
        .then(authInfo => {
          setAuthInfo(authInfo)
          firstLogin() && history.push('/admin/users?openModal=true')
        })
        .catch(handleError)
  }, [awsIntegrations, azureIntegrations, gcpIntegrations, createCancelToken, handleError])

  return (
    <>
      <NestedPageHeader>
        <NestedPageHeaderTitles
          mainHeading={t('admin.integrations.heading')}
          children={<GrayText>{t('admin.integrations.subHeading')}</GrayText>}
        />
        <NestedPageHeaderActions>
          <SpotterDocumentationLink capitalize={true} />
        </NestedPageHeaderActions>
      </NestedPageHeader>
      <AdminContentWrapper>
        <TabsContainer>
          <VendorTabs
            handleSelection={setSelectedTab}
            selectedVendor={selectedTab}
            useRoute={true}
            showAll={true}
            pingVendors={pingVendors}
          />
        </TabsContainer>
        {selectedTab === Vendor.AWS && (
          <AwsIntegrations integrations={awsIntegrations} setIntegrations={setAwsIntegrations} />
        )}
        {selectedTab === Vendor.AZURE && (
          <AzureIntegrations integrations={azureIntegrations} setIntegrations={setAzureIntegrations} />
        )}
        {selectedTab === Vendor.GCP && (
          <GcpIntegrations integrations={gcpIntegrations} setIntegrations={setGcpIntegrations} />
        )}
      </AdminContentWrapper>
    </>
  )
}

export const NewIntegration = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const { setModal } = useContext(ModalContext)

  useEffect(() => {
    !authInfo.azureIntegration && !authInfo.awsIntegration && !authInfo.gcpIntegration
      ? setModal({
          header: firstLogin()
            ? t('admin.integrations.firstModal.welcomeHeader', {
                name: authInfo.firstName
              })
            : t('common.greeting'),
          body: <FirstIntegrationModal />
        })
      : setModal(null)
  }, [authInfo, setModal, t])

  return <AdminIntegrations selectedVendor={Vendor.AWS} />
}

export const FirstIntegrationModal = () => {
  const { t } = useTranslation()

  return (
    <div className={'max-w-124 pb-1'}>
      <div className={'flex flex-col text-center gap-8 text-gray-50'}>
        <div className={'flex flex-col gap-1 text-gray-200 text-90'}>
          {firstLogin() && <CapitalizedText>{t('admin.integrations.firstModal.welcomeMessage')}</CapitalizedText>}
          <CapitalizedText>{t('admin.integrations.firstModal.firstIntegrationIntro')}</CapitalizedText>
          <CapitalizedText>{t('admin.integrations.firstModal.firstStep')}</CapitalizedText>
        </div>
        <div className={'flex h-full justify-between gap-4'}>
          <IntegrationOption vendor={Vendor.AWS} />
          <IntegrationOption vendor={Vendor.AZURE} />
          <IntegrationOption vendor={Vendor.GCP} />
        </div>
      </div>
    </div>
  )
}

interface IntegrationOptionProps {
  vendor: Vendor
}

const IntegrationOption = ({ vendor }: IntegrationOptionProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const path = vendor === Vendor.AWS ? awsIcon : vendor === Vendor.AZURE ? azureIcon : gcpIcon
  return (
    <div
      className={
        'flex flex-col justify-between text-center items-center gap-5 px-4 py-6 border border-gray-500 rounded-lg transition-all ease-linear duration-150 hover:transform hover:scale-102 hover:shadow-xs'
      }
    >
      <CustomIcon iconType={IconType.VECTOR} path={path} styles={'w-12 h-12 bg-gray-50'} />
      <CapitalizedText className={'max-w-28'}>{t(`vendors.${vendor}.long`)}</CapitalizedText>
      <Button
        value={t('common.continue')}
        size={ButtonSize.XSMALL}
        clickHandler={() => history.push(`/admin/integrations/${vendor.toLowerCase()}?first=true`)}
      />
    </div>
  )
}
