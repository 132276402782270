import { useContext, useEffect, useRef, useState } from 'react'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import {
  deleteOptimizationFilterPreset,
  getOptimizationFilterOptions,
  getOptimizationFilterPresets,
  OptimizationFilterOptions,
  OptimizationFilterPreset,
  saveOptimizationFilterPreset
} from '../../api/optimization/shared'
import { CurrencyFilterOption, FilterMenu, Filters, FilterType, SelectedFilterOption } from '../shared/filters/Filters'
import { FilterOption } from '../shared/filters/FilterSelect'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'
import { useTranslation } from 'react-i18next'
import { ModalContext, ModalState } from '../../state/context/ModalContext'
import { UserInfoContext } from '../../state/context/UserInfoContext'

interface FilterProps {
  selectedVendors: FilterOption[]
  setSelectedVendors: (selectedVendors: FilterOption[]) => void
  selectedProjects: FilterOption[]
  setSelectedProjects: (selectedProjects: FilterOption[]) => void
  selectedRecommendations: FilterOption[]
  setSelectedRecommendations: (selectedRecommendations: FilterOption[]) => void
  selectedCurrency: CurrencyFilterOption
  setSelectedCurrency: (selectedCurrency: CurrencyFilterOption) => void
}

export const OptimizationFilters = ({
  selectedVendors,
  setSelectedVendors,
  selectedProjects,
  setSelectedProjects,
  selectedRecommendations,
  setSelectedRecommendations,
  selectedCurrency,
  setSelectedCurrency
}: FilterProps) => {
  const { userSettings } = useContext(UserInfoContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { setMessage } = useContext<MessageState>(MessageContext)
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const isInitialMount = useRef<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [initFilterOptions, setInitFilterOptions] = useState<OptimizationFilterOptions | null>(null)
  const [filterOptions, setFilterOptions] = useState<OptimizationFilterOptions | null>(null)
  const [selectedPreset, setSelectedPreset] = useState<FilterOption | null>(null)
  const [filterPresets, setFilterPresets] = useState<OptimizationFilterPreset[]>([])
  const vendorOptions = userSettings.visibleVendors.map(v => ({
    label: t(`vendors.${v}.short`),
    value: v
  }))

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    Promise.all([
      getOptimizationFilterOptions(cancelToken.token).then(options => {
        setInitFilterOptions(options)
        setFilterOptions(options)
      }),
      getOptimizationFilterPresets(cancelToken.token).then(setFilterPresets)
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      setLoading(false)
      setFilterPresets([])
      setFilterOptions(null)
    }
  }, [createCancelToken, handleError])

  useEffect(() => {
    if (initFilterOptions) {
      const projects = initFilterOptions.projects.filter(project =>
        selectedVendors.some(vendor => vendor.value === project.label)
      )
      const recommendations = initFilterOptions.recommendations.filter(project =>
        selectedVendors.some(vendor => vendor.value === project.label)
      )
      setFilterOptions({
        projects,
        recommendations,
        currencies: initFilterOptions.currencies
      })
    } else {
      setFilterOptions(initFilterOptions)
    }
  }, [initFilterOptions, selectedVendors])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const chosenPreset = filterPresets.find(filter => filter.name === selectedPreset?.value)
      setSelectedVendors(chosenPreset?.vendors ?? vendorOptions)
      setSelectedProjects(chosenPreset?.projects ?? [])
      setSelectedRecommendations(chosenPreset?.recommendations ?? [])
    }
  }, [selectedPreset])

  const savePreset = (name: string) => {
    const cancelToken = createCancelToken()
    const preset: OptimizationFilterPreset = {
      name: name,
      vendors: selectedVendors.map(vendor => ({ label: vendor.label, value: vendor.value })),
      projects: selectedProjects.map(project => ({ label: project.label, value: project.value })),
      recommendations: selectedRecommendations.map(recommendation => ({
        label: recommendation.label,
        value: recommendation.value
      }))
    }
    saveOptimizationFilterPreset(preset, cancelToken.token)
      .then(savedPreset => {
        setFilterPresets([...filterPresets].filter(preset => preset.name !== savedPreset.name).concat(savedPreset))
        setSelectedPreset({
          label: savedPreset.name,
          value: savedPreset.name
        })
        setMessage({
          message: t('filters.presetSaveSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const deletePreset = (name: string) => {
    const cancelToken = createCancelToken()
    deleteOptimizationFilterPreset(name, cancelToken.token)
      .then(() => {
        setSelectedPreset(null)
        getOptimizationFilterPresets(cancelToken.token).then(setFilterPresets)
        setMessage({
          message: t('filters.presetDeleteSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const options: FilterMenu[] = [
    {
      type: FilterType.VENDORS,
      value: selectedVendors,
      options: vendorOptions,
      onChange: setSelectedVendors
    },
    {
      type: FilterType.PROJECTS,
      value: selectedProjects,
      options: filterOptions?.projects ?? [],
      onChange: setSelectedProjects
    },
    {
      type: FilterType.RECOMMENDATIONS,
      value: selectedRecommendations,
      options: filterOptions?.recommendations ?? [],
      onChange: setSelectedRecommendations
    },
    {
      type: FilterType.CURRENCY,
      value: [selectedCurrency],
      options: filterOptions?.currencies ?? [],
      onChange: setSelectedCurrency
    }
  ]

  const selectedOptions: SelectedFilterOption[] = [
    {
      type: FilterType.VENDORS,
      options: selectedVendors.length > 0 ? selectedVendors : vendorOptions,
      clickHandler: setSelectedVendors
    },
    {
      type: FilterType.PROJECTS,
      options: selectedProjects,
      clickHandler: setSelectedProjects
    },
    {
      type: FilterType.RECOMMENDATIONS,
      options: selectedRecommendations,
      clickHandler: setSelectedRecommendations
    },
    {
      type: FilterType.CURRENCY,
      options: [selectedCurrency],
      clickHandler: setSelectedCurrency
    }
  ]

  return (
    <Filters
      filterMenuOptions={options}
      selectedOptions={selectedOptions}
      saveAction={savePreset}
      deleteAction={deletePreset}
      selectedPreset={selectedPreset}
      setSelectedPreset={setSelectedPreset}
      savedPresets={filterPresets.map(preset => preset.name)}
      loading={loading}
    />
  )
}
