import { GrayText, WhiteText } from '../../shared/TextComponents'
import { NotificationLimitsInput } from '../../shared/NotificationLimitsInput'
import { ChangeType, LimitsByCostAnomalyState } from '../../../api/notifications'
import { CurrencyId, getCurrency } from '../../../utils/Currency'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

interface CostAnomalyInputsProps {
  percent: LimitsByCostAnomalyState
  setPercent: (limits: LimitsByCostAnomalyState) => void
  impact: LimitsByCostAnomalyState
  setImpact: (limits: LimitsByCostAnomalyState) => void
  currency: CurrencyId
}

export const CostAnomalySettingsInputs = ({
  percent,
  setPercent,
  impact,
  setImpact,
  currency
}: CostAnomalyInputsProps) => {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <WhiteText className={'first-letter:capitalize text-80 font-semibold'}>
          {t('settings.notifications.changePercent')}
        </WhiteText>
        <Row>
          <GrayText>{t('notifications.costAnomalies.costAnomaly.limitChangeModal.decrease')}</GrayText>
          <NotificationLimitsInput
            limits={percent}
            setLimits={setPercent}
            min={-100}
            max={-1}
            type={ChangeType.DECREASING}
          />
        </Row>
        <Row>
          <GrayText>{t('notifications.costAnomalies.costAnomaly.limitChangeModal.increase')}</GrayText>
          <NotificationLimitsInput
            limits={percent}
            setLimits={setPercent}
            min={1}
            max={100}
            type={ChangeType.INCREASING}
          />
        </Row>
      </div>

      <div>
        <WhiteText className={'first-letter:capitalize text-80 font-semibold'}>
          {t('settings.notifications.monthlyImpact', { currency: getCurrency(currency).symbol })}
        </WhiteText>
        <Row>
          <GrayText>{t('notifications.costAnomalies.costAnomaly.limitChangeModal.decrease')}</GrayText>
          <NotificationLimitsInput
            limits={impact}
            setLimits={setImpact}
            min={-99999}
            max={-1}
            type={ChangeType.DECREASING}
          />
        </Row>
        <Row>
          <GrayText>{t('notifications.costAnomalies.costAnomaly.limitChangeModal.increase')}</GrayText>
          <NotificationLimitsInput
            limits={impact}
            setLimits={setImpact}
            min={1}
            max={99999}
            type={ChangeType.INCREASING}
          />
        </Row>
      </div>
    </>
  )
}

const Row = styled.div`
  ${tw`flex w-full justify-between items-center gap-5 py-1 text-90 first:pt-0 last:pb-0`}
`
