import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CapitalizedText } from '../shared/TextComponents'
import {
  ChangeType,
  CostAnomalyLimits,
  CostAnomalyNotification,
  getCostAnomalyNotifications,
  INCREASING_COST_ANOMALY_STATES,
  LimitsByCostAnomalyState
} from '../../api/notifications'
import { useCancelToken } from '../../api/client'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { ModalContext } from '../../state/context/ModalContext'
import { useErrorHandling } from '../../hooks/handleError'
import { ModalActions, ModalText } from '../shared/modal/Modal'
import { Button, ButtonStyle, ButtonType } from '../shared/buttons/Button'
import styled from 'styled-components'
import tw from 'twin.macro'
import { formatNumber } from '../../utils/formats'
import { NotificationsContext } from '../../state/context/NotificationsContext'
import { NotificationLimitsInput } from '../shared/NotificationLimitsInput'
import { useHistory } from 'react-router-dom'
import settings from '../../assets/svg/objects/cog.svg'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import { getCurrency } from '../../utils/Currency'
import {
  changeCostAnomalyProjectLimits,
  changeCostAnomalyServiceLimits
} from '../../api/settings/notification-settings'
import { setNotificationProject, setNotificationService } from '../../state/storage'

interface CostAnomalySettingsModalProps {
  notification: CostAnomalyNotification
}

export const CostAnomalySettingsModalHeader = ({ notification }: CostAnomalySettingsModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  const { setNotificationsOpen } = useContext(NotificationsContext)
  const history = useHistory()

  return (
    <div className={'flex gap-5 items-center text-center'}>
      <CustomIcon
        styles={'w-5 h-5 bg-gray-200 hover:bg-gray-50'}
        iconType={IconType.VECTOR}
        path={settings}
        onClick={() => {
          setModal(null)
          setNotificationsOpen(false)
          history.replace(`/settings/notifications/${notification.vendor.toLowerCase()}`)
        }}
        tooltipText={t('notifications.costAnomalies.costAnomaly.limitChangeModal.viewAllSettings')}
        tooltipStyles={'mt-12 w-max ml-5'}
      />
      <CapitalizedText className={'w-full'}>
        {t('notifications.costAnomalies.costAnomaly.menuOptions.changeLimits')}
      </CapitalizedText>
    </div>
  )
}

export const CostAnomalySettingsModalBody = ({ notification }: CostAnomalySettingsModalProps) => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const history = useHistory()
  const { setMessage } = useContext(MessageContext)
  const { setModal } = useContext(ModalContext)
  const { setNotificationsOpen } = useContext(NotificationsContext)
  const { setNotifications } = useContext(NotificationsContext)
  const handleError = useErrorHandling()
  const [projectLimits, setProjectLimits] = useState<CostAnomalyLimits>(notification.projectLimits.limits)
  const [serviceLimits, setServiceLimits] = useState<CostAnomalyLimits>(notification.serviceLimits.limits)
  const [currentLimits, setCurrentLimits] = useState<CostAnomalyLimits>(notification.anomalyLimits)
  const [loading, setLoading] = useState(false)
  const currencySymbol = getCurrency(notification.currency).symbol
  const isIncreasing = INCREASING_COST_ANOMALY_STATES.includes(notification.anomalyState)

  useEffect(() => {
    const percentLimit = isIncreasing
      ? projectLimits.dailyChangePercent.increase > serviceLimits.dailyChangePercent.increase
        ? projectLimits.dailyChangePercent
        : serviceLimits.dailyChangePercent
      : projectLimits.dailyChangePercent.decrease < serviceLimits.dailyChangePercent.decrease
        ? projectLimits.dailyChangePercent
        : serviceLimits.dailyChangePercent
    const estMonthlyImpactLimit = isIncreasing
      ? projectLimits.estMonthlyImpact.increase > serviceLimits.estMonthlyImpact.increase
        ? projectLimits.estMonthlyImpact
        : serviceLimits.estMonthlyImpact
      : projectLimits.estMonthlyImpact.decrease < serviceLimits.estMonthlyImpact.decrease
        ? projectLimits.estMonthlyImpact
        : serviceLimits.estMonthlyImpact

    setCurrentLimits({
      dailyChangePercent: percentLimit,
      estMonthlyImpact: estMonthlyImpactLimit
    })
  }, [projectLimits, serviceLimits, isIncreasing])

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (JSON.stringify(projectLimits) !== JSON.stringify(notification.projectLimits.limits)) {
      setLoading(true)
      changeCostAnomalyProjectLimits(
        notification.vendor,
        [
          {
            vendor: notification.vendor,
            project: notification.project,
            dailyChangePercent: projectLimits.dailyChangePercent,
            estMonthlyImpact: projectLimits.estMonthlyImpact,
            currency: notification.currency,
            isDefault: notification.projectLimits.isDefault,
            costNotificationsDisabledAt: null,
            isSubscribedToEmail: !!notification.emailSubscriptions?.project
          }
        ],
        cancelToken.token
      )
        .then(() => {
          getCostAnomalyNotifications(cancelToken.token)
            .then(resp => {
              setNotifications(resp)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
              })
            })
            .catch(handleError)
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }

    if (JSON.stringify(serviceLimits) !== JSON.stringify(notification.serviceLimits.limits)) {
      setLoading(true)
      changeCostAnomalyServiceLimits(
        notification.vendor,
        [
          {
            vendor: notification.vendor,
            service: notification.service,
            dailyChangePercent: serviceLimits.dailyChangePercent,
            estMonthlyImpact: serviceLimits.estMonthlyImpact,
            currency: notification.currency,
            isDefault: notification.serviceLimits.isDefault,
            isSubscribedToEmail: !!notification.emailSubscriptions?.service
          }
        ],
        cancelToken.token
      )
        .then(() => {
          getCostAnomalyNotifications(cancelToken.token)
            .then(resp => {
              setNotifications(resp)
              setMessage({
                type: MessageType.SUCCESS,
                message: t('notifications.costAnomalies.toastText.limitChangeSuccess')
              })
            })
            .catch(handleError)
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }
  }, [createCancelToken, handleError, projectLimits, serviceLimits])

  return (
    <>
      <div className={'flex flex-col items-center divide-y divide-gray-500/60 max-w-120'}>
        <ModalInputsBlock
          label={notification.project.name}
          currencySymbol={currencySymbol}
          limits={projectLimits}
          setLimits={setProjectLimits}
          type={isIncreasing ? ChangeType.INCREASING : ChangeType.DECREASING}
        />
        <ModalInputsBlock
          label={notification.service}
          currencySymbol={currencySymbol}
          limits={serviceLimits}
          setLimits={setServiceLimits}
          type={isIncreasing ? ChangeType.INCREASING : ChangeType.DECREASING}
        />
      </div>
      <TextWrapper className={'max-w-116 text-80'}>
        <Trans>
          {t('notifications.costAnomalies.costAnomaly.limitChangeModal.limitsDescription', {
            changeLabel: isIncreasing
              ? t('notifications.costAnomalies.costAnomaly.limitChangeModal.increase')
              : t('notifications.costAnomalies.costAnomaly.limitChangeModal.decrease'),
            percent: isNaN(
              isIncreasing ? currentLimits.dailyChangePercent.increase : currentLimits.dailyChangePercent.decrease
            )
              ? 1
              : isIncreasing
                ? currentLimits.dailyChangePercent.increase
                : currentLimits.dailyChangePercent.decrease,
            impact: isNaN(
              isIncreasing ? currentLimits.estMonthlyImpact.increase : currentLimits.estMonthlyImpact.decrease
            )
              ? 1
              : formatNumber(
                  isIncreasing ? currentLimits.estMonthlyImpact.increase : currentLimits.estMonthlyImpact.decrease
                ),
            currency: getCurrency(notification.currency).symbol
          })}
        </Trans>
      </TextWrapper>
      <ModalActions>
        <Button
          value={t('notifications.costAnomalies.costAnomaly.limitChangeModal.viewSettings')}
          clickHandler={() => {
            setNotificationProject(notification.project.id)
            setNotificationService(notification.service)
            setNotificationsOpen(false)
            setModal(null)
            history.push(`/settings/notifications/${notification.vendor.toLowerCase()}`)
          }}
          buttonStyle={ButtonStyle.SECONDARY}
          type={ButtonType.FORM}
          disabled={loading}
        />
      </ModalActions>
    </>
  )
}

interface ModalInputsBlockProps {
  label: string
  currencySymbol: string
  limits: CostAnomalyLimits
  setLimits: (limits: CostAnomalyLimits) => void
  type: ChangeType
}

const ModalInputsBlock = ({ label, currencySymbol, limits, setLimits, type }: ModalInputsBlockProps) => {
  const { t } = useTranslation()
  const [percent, setPercent] = useState<LimitsByCostAnomalyState>(limits.dailyChangePercent)
  const [monthlyImpact, setMonthlyImpact] = useState<LimitsByCostAnomalyState>(limits.estMonthlyImpact)

  useEffect(() => {
    setLimits({
      dailyChangePercent: percent,
      estMonthlyImpact: monthlyImpact
    })
  }, [percent, monthlyImpact])

  return (
    <div className={'flex flex-col gap-4 w-full px-5 py-9 first:pt-5'}>
      <div className={'text-center text-gray-50 font-semibold'}>{label}</div>
      <div className={'flex flex-col gap-4 w-full items-center'}>
        <InputWrapper>
          <TextWrapper className={'text-90'}>
            {t('notifications.costAnomalies.costAnomaly.limitChangeModal.dailyChangePercent')}
          </TextWrapper>
          <NotificationLimitsInput
            limits={percent}
            setLimits={setPercent}
            min={type === ChangeType.DECREASING ? -100 : 1}
            max={type === ChangeType.INCREASING ? 100 : -1}
            type={type}
          />
        </InputWrapper>
        <InputWrapper>
          <TextWrapper className={'text-90'}>
            {t('notifications.costAnomalies.costAnomaly.limitChangeModal.monthlyImpact', { currency: currencySymbol })}
          </TextWrapper>
          <NotificationLimitsInput
            limits={monthlyImpact}
            setLimits={setMonthlyImpact}
            min={type === ChangeType.DECREASING ? -99999 : 1}
            max={type === ChangeType.INCREASING ? 99999 : -1}
            type={type}
          />
        </InputWrapper>
      </div>
    </div>
  )
}

const InputWrapper = styled.div`
  ${tw`flex w-full justify-between items-center gap-5`}
  div:nth-child(2) {
    ${tw`w-2/5`}
  }
`

const TextWrapper = styled(ModalText)`
  strong {
    ${tw`text-gray-50`}
  }
`
