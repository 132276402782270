import styled from 'styled-components'
import check from '../../../assets/svg/symbols/check-heavy.svg'
import checkIcon from '../../../assets/svg/symbols/check-heavy.svg'
import React, { useState } from 'react'
import tw from 'twin.macro'
import { CustomIcon, IconType } from '../CustomIcon'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { useMousePosition } from '../../../hooks/useMousePosition'
import { CapitalizedText, GrayText } from '../TextComponents'
import plusIcon from '../../../assets/svg/symbols/plus-heavy.svg'

export interface CheckboxOptionProps {
  clickHandler: () => void
  checked: boolean
  label: React.ReactNode
  labelColor?: string
  value?: string
  disabled?: boolean
  tooltipText?: string
  type?: 'small' | 'default' | 'filter' | 'secondary' | 'container'
  capitalize?: boolean
}

export const CheckboxOption = ({
  clickHandler,
  checked,
  label,
  labelColor,
  value,
  disabled = false,
  tooltipText,
  type = 'default',
  capitalize = false
}: CheckboxOptionProps) => {
  const mousePos = useMousePosition()
  const [showTooltip, setShowTooltip] = useState(false)
  const handleClick = !disabled ? clickHandler : tooltipText ? () => setShowTooltip(true) : undefined

  if (type === 'container')
    return (
      <div
        className={`flex w-full gap-5 text-gray-50 items-center justify-between px-4 py-3 rounded transition-all ease duration-200 cursor-pointer ${checked ? 'border border-gray-50/80 shadow-sm' : 'border border-gray-50/20 hover:border-gray-50/60'}`}
        onClick={handleClick}
      >
        {label}
        <CustomIcon path={check} styles={`w-4 h-4 ${checked ? 'bg-gray-50' : 'bg-transparent'}`} />
      </div>
    )

  if (type === 'secondary')
    return (
      <div
        className={`group flex w-full items-center gap-3 ${!disabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
        onClick={handleClick}
      >
        <CustomIcon
          path={checked ? checkIcon : plusIcon}
          styles={`w-4 h-4 ${checked ? 'bg-success-100' : disabled ? 'bg-gray-300/60' : 'bg-gray-300'} ${!disabled && !checked && 'group-hover:bg-gray-50/80'}`}
        />
        <div
          className={`text-90 ${checked ? 'text-gray-50' : 'text-gray-200'} ${disabled ? 'text-gray-200/60' : !checked && 'group-hover:text-gray-50/80'}`}
        >
          {label}
        </div>
      </div>
    )

  return (
    <OptionWrapper type={type} disabled={disabled} onClick={handleClick} onMouseLeave={() => setShowTooltip(false)}>
      <CheckBox checked={checked} disabled={disabled}>
        <CustomIcon
          styles={`${type === 'default' ? 'w-4 h-4' : 'w-2.5 h-2.5'} ${
            checked ? (disabled && type !== 'filter' ? 'bg-gray-200' : 'bg-gray-50') : 'bg-transparent'
          }`}
          iconType={IconType.VECTOR}
          path={check}
        />
      </CheckBox>
      <Values type={type} disabled={disabled} className={`text-${labelColor ?? 'gray-50'}`}>
        {capitalize ? <CapitalizedText>{label}</CapitalizedText> : <div>{label}</div>}
        {value && <GrayText className={'text-90'}>{value}</GrayText>}
      </Values>
      {showTooltip && (
        <InfoTooltip x={mousePos.x} y={mousePos.y}>
          {tooltipText}
        </InfoTooltip>
      )}
    </OptionWrapper>
  )
}

interface Props {
  disabled: boolean
  checked?: boolean
  type?: 'small' | 'default' | 'filter' | 'secondary' | 'container'
}

const OptionWrapper = styled.div<Props>`
  ${tw`flex w-max gap-4 items-center`}
  ${({ type }) => type !== 'default' && tw`gap-3`}
  ${({ type }) => type === 'filter' && tw`w-full`}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`)}
`

const CheckBox = styled.div<Props>`
  ${tw`p-0.5 border-2 border-gray-400 rounded`}
  ${({ checked, disabled, type }) =>
    checked || (checked && disabled && type === 'filter')
      ? tw`border-transparent bg-primary-500`
      : checked && disabled
        ? tw`border-transparent bg-primary-800`
        : tw`bg-gray-600`}
`

const Values = styled.div<Props>`
  ${tw`flex flex-col w-full`}
  ${({ type }) => type === 'filter' && tw`text-gray-100 hover:text-gray-50`}
  ${({ disabled, type }) => disabled && type !== 'filter' && tw`text-gray-200 hover:text-gray-200`}
`
