import styled from 'styled-components'
import { CapitalizedText, GrayText, Heading, Heading3 } from '../../shared/TextComponents'
import tw from 'twin.macro'
import { FilterOption } from '../../shared/filters/FilterSelect'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../../shared/buttons/Button'
import React, { MouseEventHandler, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalActions } from '../../shared/modal/Modal'
import { formatDate } from '../../../utils/formats'
import { NestedPageHeader, NestedPageHeaderActions, NestedPageHeaderTitles } from '../../shared/NestedPageHeader'
import { AdminContentWrapper, AdminTabContent } from '../adminStyles'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import bin from '../../../assets/svg/objects/trashcan.svg'
import { Loading } from '../../shared/Loading'
import { SpotterDocumentationLink } from '../../login/Shared'
import plus from '../../../assets/svg/symbols/plus.svg'
import recover from '../../../assets/svg/actions/return.svg'
import saveIcon from '../../../assets/svg/actions/save.svg'
import save from '../../../assets/svg/actions/save.svg'
import editIcon from '../../../assets/svg/actions/edit.svg'
import point from '../../../assets/svg/gestures/point.svg'
import { PingType, Tab } from '../../shared/tabs/Tab'
import { TabOptionsWrapper } from '../../shared/tabs/TabSharedComponents'
import caret from '../../../assets/svg/directional/caret.svg'
import { t } from 'i18next'
import { ModalContext } from '../../../state/context/ModalContext'
import { Alert } from '../../shared/indicators/Alert'

export enum IntegrationsTab {
  DETAILS = 'details',
  INTEGRATIONS = 'integrations'
}

export const IntegrationsModalTitle = styled(CapitalizedText)`
  ${tw`w-full text-center text-112 text-gray-100 font-semibold`}
`

export const IntegrationsModalBody = styled.div`
  ${tw`flex flex-col gap-7 items-center w-full max-w-130`}
`

export const IntegrationsModalInputs = styled.div`
  ${tw`flex flex-col gap-4 w-full`}
`

export const IntegrationDetailsWrapper = styled.div`
  ${tw`divide-y divide-gray-500 w-full`}
`

interface IntegrationTabsProps {
  hasErrors?: boolean
  selectedTab: IntegrationsTab
  setSelectedTab: (tab: IntegrationsTab) => void
}

export const IntegrationsTabs = ({ hasErrors, selectedTab, setSelectedTab }: IntegrationTabsProps) => {
  return (
    <TabOptionsWrapper>
      <Tab
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(IntegrationsTab.DETAILS)}
        tabValue={IntegrationsTab.DETAILS}
      />
      <Tab
        selectedTab={selectedTab}
        handleSelection={() => setSelectedTab(IntegrationsTab.INTEGRATIONS)}
        tabValue={IntegrationsTab.INTEGRATIONS}
        ping={hasErrors && PingType.ERROR}
      />
    </TabOptionsWrapper>
  )
}

interface IntegrationDetailsInfoSectionProps {
  removeDate: Date | undefined
  selectedTab: IntegrationsTab
}

export const IntegrationDetailsInfoSection = ({ removeDate, selectedTab }: IntegrationDetailsInfoSectionProps) => {
  const { t } = useTranslation()

  if (!removeDate && selectedTab === IntegrationsTab.DETAILS) return null

  return (
    <AdminTabContent>
      {removeDate && <RemovedIntegrationMessage deletedAt={removeDate} />}
      {selectedTab === IntegrationsTab.INTEGRATIONS && (
        <CapitalizedText>{t('admin.integrations.lastIntegrationsText')}</CapitalizedText>
      )}
    </AdminTabContent>
  )
}

interface RemovedIntegrationMessageProps {
  deletedAt: Date
}

const RemovedIntegrationMessage = ({ deletedAt }: RemovedIntegrationMessageProps) => {
  const { t } = useTranslation()
  const deleteDate = new Date(deletedAt)
  const terminateDate = formatDate(new Date(deleteDate.setDate(deleteDate.getDate() + 30)), true, false)
  return (
    <div className={'text-error-100'}>
      <CapitalizedText>
        {t('admin.integrations.integrationRemovedOn', { date: formatDate(deletedAt, true, false) })}
      </CapitalizedText>
      <CapitalizedText className={'font-semibold'}>
        {t('admin.integrations.integrationTerminateOn', { date: terminateDate })}
      </CapitalizedText>
    </div>
  )
}

type IntegrationDetailsHeaderProps = {
  header: string
} & (
  | {
      headerAction: 'formActions'
      isEditable: boolean
      isOnEditMode: boolean
      disableSubmit: boolean
      submitAction: MouseEventHandler<HTMLButtonElement>
      isDirty: boolean
      resetAction: MouseEventHandler<HTMLButtonElement>
    }
  | {
      headerAction: MouseEventHandler<HTMLButtonElement> | false
      isOnEditMode?: never
      isEditable?: never
      disableSubmit?: never
      submitAction?: never
      isDirty?: never
      resetAction?: never
    }
)

export const IntegrationDetailsHeader = ({
  header,
  headerAction,
  isEditable,
  isOnEditMode,
  disableSubmit,
  submitAction,
  isDirty,
  resetAction
}: IntegrationDetailsHeaderProps) => {
  return (
    <div className={'flex w-full justify-between'}>
      <Heading>{header}</Heading>
      {headerAction === 'formActions'
        ? isEditable && (
            <div className={'flex gap-4'}>
              <Button
                tooltipText={isOnEditMode ? t('common.saveChanges') : t('common.edit')}
                size={ButtonSize.XSMALL}
                type={ButtonType.ICON}
                disabled={disableSubmit}
                clickHandler={submitAction}
                value={
                  <CustomIcon
                    styles={'w-6 h-6 bg-gray-50'}
                    iconType={IconType.VECTOR}
                    path={isOnEditMode ? save : editIcon}
                  />
                }
              />
              {isOnEditMode && isDirty && (
                <Button
                  size={ButtonSize.XSMALL}
                  type={ButtonType.ICON}
                  buttonStyle={ButtonStyle.SECONDARY}
                  clickHandler={resetAction}
                  tooltipText={t('admin.integrations.resetChanges')}
                  value={<CustomIcon styles={'w-6 h-6 bg-gray-50'} iconType={IconType.VECTOR} path={recover} />}
                />
              )}
            </div>
          )
        : headerAction && (
            <Button
              size={ButtonSize.XSMALL}
              type={ButtonType.ICON}
              tooltipText={t('admin.integrations.addNew')}
              value={<CustomIcon styles={'w-6 h-6 bg-gray-50'} iconType={IconType.VECTOR} path={plus} />}
              clickHandler={headerAction}
            />
          )}
    </div>
  )
}

type IntegrationDetailRowsWrapperProps = {
  detailRows: React.ReactNode
} & (
  | {
      hasActions: true
      isRemoved: boolean
      isOnEditMode?: boolean
      isDirty?: boolean
      disableSubmit?: boolean
      submitAction?: MouseEventHandler<HTMLButtonElement>
      resetAction?: MouseEventHandler<HTMLButtonElement>
      statusAction: MouseEventHandler<HTMLButtonElement>
    }
  | {
      hasActions: false
      isRemoved?: never
      isOnEditMode?: never
      isDirty?: never
      disableSubmit?: never
      submitAction?: never
      resetAction?: never
      statusAction?: never
    }
)

export const IntegrationDetailRowsWrapper = ({
  detailRows,
  hasActions,
  isRemoved = false,
  isDirty = false,
  isOnEditMode = false,
  disableSubmit = false,
  submitAction,
  resetAction,
  statusAction
}: IntegrationDetailRowsWrapperProps) => {
  const { t } = useTranslation()
  return (
    <AccountDetailsWrapper>
      <div className={'w-full'}>{detailRows}</div>
      {hasActions && (
        <div className={'w-full'}>
          <>
            {!isRemoved && submitAction && (
              <Button
                size={ButtonSize.XSMALL}
                type={ButtonType.ICON}
                buttonStyle={ButtonStyle.GHOST}
                tooltipText={isOnEditMode ? t('common.saveChanges') : t('common.edit')}
                disabled={isRemoved || disableSubmit}
                value={
                  <CustomIcon
                    styles={'w-6 h-6 bg-gray-200 hover:bg-gray-50'}
                    iconType={IconType.VECTOR}
                    path={isOnEditMode ? saveIcon : editIcon}
                  />
                }
                clickHandler={submitAction}
              />
            )}
            {!isRemoved && isOnEditMode && isDirty && resetAction && (
              <Button
                size={ButtonSize.XSMALL}
                type={ButtonType.ICON}
                buttonStyle={ButtonStyle.GHOST}
                tooltipText={t('admin.integrations.resetChanges')}
                value={
                  <CustomIcon
                    styles={'w-6 h-6 bg-gray-200 hover:bg-gray-50'}
                    iconType={IconType.VECTOR}
                    path={recover}
                  />
                }
                clickHandler={resetAction}
              />
            )}
          </>
          <Button
            size={ButtonSize.XSMALL}
            type={ButtonType.ICON}
            buttonStyle={ButtonStyle.GHOST}
            tooltipText={isRemoved ? t('admin.integrations.recover') : t('admin.integrations.remove')}
            value={
              <CustomIcon
                styles={'w-6 h-6 bg-gray-200 hover:bg-gray-50'}
                iconType={IconType.VECTOR}
                path={isRemoved ? recover : bin}
              />
            }
            clickHandler={statusAction}
          />
        </div>
      )}
    </AccountDetailsWrapper>
  )
}

const AccountDetailsWrapper = styled.div`
  ${tw`flex justify-between rounded-sm border border-gray-500/20`}
  > div:first-child {
    ${tw`w-full`}
    > div {
      ${tw`m-0 border-b-8 border-solid border-gray-600 last:border-none`}
    }
  }

  > div:nth-child(2) {
    ${tw`flex flex-col gap-3 w-min border border-0 border-gray-600 bg-gray-500/20 border-l-12`}
  }
`

interface IntegrationAccountStatusProps {
  accountDeleteDate: Date | undefined
}

export const IntegrationAccountStatusRow = ({ accountDeleteDate }: IntegrationAccountStatusProps) => {
  const { t } = useTranslation()
  const deleteDate = accountDeleteDate && new Date(accountDeleteDate)
  const terminateDate = deleteDate && formatDate(new Date(deleteDate.setDate(deleteDate.getDate() + 30)), true, false)
  return (
    <>
      {!accountDeleteDate ? (
        <IntegrationDetailRow
          label={t('admin.integrations.status')}
          value={t('common.status.active')}
          capitalize={true}
        />
      ) : (
        <IntegrationDetailRow
          label={t('admin.integrations.status')}
          value={
            <div className={'text-error-500 text-90 text-right break-normal'}>
              <CapitalizedText>
                {t('admin.integrations.removedAt')}: {formatDate(accountDeleteDate, true, false)}
              </CapitalizedText>
              <CapitalizedText>{t('admin.integrations.dataAvailableUntil', { date: terminateDate })}</CapitalizedText>
            </div>
          }
        />
      )}
    </>
  )
}

type DetailRowProps = {
  label: string
  value: FilterOption | React.ReactNode
  error?: boolean
  capitalize?: boolean
  isCollapsed?: boolean
  collapseHandler?: () => void
}

export const IntegrationDetailRow = ({
  label,
  value,
  error,
  capitalize,
  isCollapsed,
  collapseHandler
}: DetailRowProps) => {
  return (
    <DetailWrapper
      className={`${capitalize && 'capitalize'} ${collapseHandler && 'group cursor-pointer hover:text-gray-50'}`}
      onClick={collapseHandler}
    >
      {isCollapsed !== undefined && collapseHandler !== undefined ? (
        <div className={'flex flex-row gap-3 items-center'}>
          <CustomIcon
            iconType={IconType.VECTOR}
            path={caret}
            styles={`w-5 h-5 bg-gray-200 group-hover:bg-gray-50 ${isCollapsed ? 'rotate-180' : 'rotate-0'}`}
          />
          <CapitalizedText>{label}</CapitalizedText>
        </div>
      ) : (
        <CapitalizedText className={'min-w-max'}>{label}</CapitalizedText>
      )}
      {typeof value === 'object' && value && 'value' in value && 'label' in value ? (
        <div className={'flex gap-2 items-center'}>
          <div>
            <Value>{value.value}</Value>
            <GrayText className={'text-90'}>{value.label}</GrayText>
          </div>
          {error && <Alert size={5} />}
        </div>
      ) : (
        <div className={'flex gap-2 items-center'}>
          <Value>{value}</Value>
          {error && <Alert size={5} />}
        </div>
      )}
    </DetailWrapper>
  )
}

const DetailWrapper = styled.div`
  ${tw`flex p-2 w-full text-gray-200 justify-between items-center gap-12 bg-gray-500/20 mb-2 last:mb-0`}
  > div:last-child {
    ${tw`text-right`}
  }
`

const Value = styled.div`
  ${tw`break-all`}
`

interface IntegrationModalProps {
  description: string
  detailRows: { label: string; value: React.ReactNode | FilterOption }[]
  action: () => void
  actionLabel: string
}

export const DetailedIntegrationModal = ({ description, detailRows, action, actionLabel }: IntegrationModalProps) => {
  const { setModal } = useContext(ModalContext)
  return (
    <>
      <IntegrationsModalBody>
        <IntegrationModalHighlight type={'highlight'} highlight={description} />
        <div className={'w-full'}>
          {detailRows.map((row, index) => (
            <IntegrationDetailRow key={index} label={row.label} value={row.value} />
          ))}
        </div>
      </IntegrationsModalBody>
      <ModalActions>
        <Button
          value={t('common.cancel')}
          clickHandler={() => setModal(null)}
          type={ButtonType.FORM}
          buttonStyle={ButtonStyle.SECONDARY}
          size={ButtonSize.SMALL}
        />
        <Button
          value={actionLabel}
          buttonStyle={ButtonStyle.PRIMARY}
          size={ButtonSize.SMALL}
          clickHandler={action}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </>
  )
}

interface DetailsPageHeaderProps {
  name: string
  lastIntegrationAt?: Date
  buttonValue?: string
  buttonAction: (() => void) | false
  deleteAction: (() => void) | false
}

export const IntegrationDetailsPageHeader = ({
  name,
  lastIntegrationAt,
  buttonValue,
  buttonAction,
  deleteAction
}: DetailsPageHeaderProps) => {
  const { t } = useTranslation()

  return (
    <>
      <NestedPageHeaderTitles
        mainHeading={name}
        children={
          <GrayText>{`${t('admin.integrations.lastIntegrationAt')} ${formatDate(
            lastIntegrationAt,
            true,
            true,
            true
          )}`}</GrayText>
        }
      />
      <NestedPageHeaderActions>
        {buttonAction && <Button value={buttonValue} size={ButtonSize.XSMALL} clickHandler={() => buttonAction()} />}
        {deleteAction && (
          <Button
            size={ButtonSize.XSMALL}
            type={ButtonType.ICON}
            tooltipText={t('admin.integrations.remove')}
            clickHandler={deleteAction}
            value={<CustomIcon styles={'w-6 h-6 bg-gray-50'} iconType={IconType.VECTOR} path={bin} />}
          />
        )}
      </NestedPageHeaderActions>
    </>
  )
}

export const LoadingIntegrationDetails = () => {
  const { t } = useTranslation()
  return (
    <div className={'flex flex-col w-full'}>
      <NestedPageHeader backButtonPath={'/admin/integrations'}>
        <NestedPageHeaderTitles mainHeading={t('admin.integrations.integrationDetails')} />
      </NestedPageHeader>
      <AdminContentWrapper>
        <Loading paddingY={'10rem'} />
      </AdminContentWrapper>
    </div>
  )
}

type IntegrationHighlightProps =
  | {
      type: 'create'
      path: string
      highlight?: never
    }
  | {
      type: 'highlight'
      highlight: string
      path?: never
    }

export const IntegrationModalHighlight = ({ type, path, highlight }: IntegrationHighlightProps) => {
  const { t } = useTranslation()

  return (
    <div className={'flex gap-6 items-center justify-between px-6 py-4 border border-gray-500 rounded-sm'}>
      <CustomIcon iconType={IconType.FLAT} path={point} styles={'w-6 h-6 min-w-6'} />
      <CapitalizedText className={'text-gray-200 max-w-full text-90'}>
        {type === 'create' && path ? (
          <>
            {t('admin.integrations.integrationInstructions_1')}
            <SpotterDocumentationLink label={t('admin.integrations.thisGuide')} path={path} />.{' '}
            <div>{t('admin.integrations.integrationInstructions_2')}</div>
          </>
        ) : (
          highlight
        )}
      </CapitalizedText>
    </div>
  )
}

interface IntegrationTimestampsSectionLayoutProps {
  timestampComponent: JSX.Element
  errorSolutions?: string | false
}

export const IntegrationTimestampsSection = ({
  timestampComponent,
  errorSolutions
}: IntegrationTimestampsSectionLayoutProps) => {
  const { t } = useTranslation()
  return (
    <div className={'xl:flex'}>
      {timestampComponent}
      {errorSolutions && (
        <AdminTabContent>
          <Heading3 className={'font-bold tracking-wide'}>{t('admin.integrations.errorSolutions')}</Heading3>
          <GrayText>{errorSolutions}</GrayText>
        </AdminTabContent>
      )}
    </div>
  )
}
