import styled from 'styled-components'
import tw from 'twin.macro'
import { ScrollContainer } from '../shared/containers/ScrollContainer'

export const AdminTabContent = styled.div`
  ${tw`flex flex-col w-full gap-8 py-9 px-1 sm:px-6 xl:max-w-6/12`};
  @media (max-width: 640px) {
    ${tw`text-80`}
  }
`

export const AdminContentWrapper = styled(ScrollContainer)`
  ${tw`flex flex-col w-full gap-8 px-1 py-6 sm:p-8`}
`
