import React, { useContext, useEffect, useRef, useState } from 'react'
import { FilterOption } from '../shared/filters/FilterSelect'
import {
  deleteSustainabilityFilterPreset,
  getSavedSustainabilityFilterPresets,
  getSustainabilityFilterData,
  saveSustainabilityFilterPreset,
  SustainabilityFilterData,
  SustainabilityFilterPreset
} from '../../api/sustainability'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { FilterMenu, Filters, FilterType, SelectedFilterOption } from '../shared/filters/Filters'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'
import { useTranslation } from 'react-i18next'
import { ModalContext, ModalState } from '../../state/context/ModalContext'
import { Vendor } from '../../utils/vendors'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { TimeframeOption } from '../../utils/classes'
import { TimeframeFilter } from '../shared/filters/TimeframeFilter'

interface FilterProps {
  selectedTimeframe: TimeframeOption
  setSelectedTimeframe: (timeframe: TimeframeOption) => void
  selectedVendors: FilterOption[]
  setSelectedVendors: (selectedVendors: FilterOption[]) => void
  selectedProjects: FilterOption[]
  setSelectedProjects: (selectedProjects: FilterOption[]) => void
  selectedResourceGroups: FilterOption[]
  setSelectedResourceGroups: (selectedProjects: FilterOption[]) => void
  selectedServices: FilterOption[]
  setSelectedServices: (selectedProjects: FilterOption[]) => void
  selectedRegions: FilterOption[]
  setSelectedRegions: (selectedProjects: FilterOption[]) => void
}

export const SustainabilityFilters = ({
  selectedTimeframe,
  setSelectedTimeframe,
  selectedVendors,
  setSelectedVendors,
  selectedProjects,
  setSelectedProjects,
  selectedResourceGroups,
  setSelectedResourceGroups,
  selectedServices,
  setSelectedServices,
  selectedRegions,
  setSelectedRegions
}: FilterProps) => {
  const { setMessage } = useContext<MessageState>(MessageContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { authInfo, userSettings } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [filterOptions, setFilterOptions] = useState<SustainabilityFilterData | null>(null)
  const [initFilterOptions, setInitFilterOptions] = useState<SustainabilityFilterData | null>(null)
  const [savedFilterPreset, setSavedFilterPreset] = useState<SustainabilityFilterPreset[]>([])
  const [selectedPreset, setSelectedPreset] = useState<FilterOption | null>(null)
  const isInitialMount = useRef<boolean>(true)
  const vendorOptions = userSettings.visibleVendors.map(v => ({
    label: t(`vendors.${v}.short`),
    value: v
  }))

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    Promise.all([
      getSustainabilityFilterData(
        selectedProjects.map(p => p.value),
        cancelToken.token
      ).then(data => {
        setFilterOptions(data)
        setInitFilterOptions(data)
      }),
      getSavedSustainabilityFilterPresets(cancelToken.token).then(setSavedFilterPreset)
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setSavedFilterPreset([])
      setInitFilterOptions(null)
      setFilterOptions(null)
    }
  }, [selectedProjects, createCancelToken, handleError])

  useEffect(() => {
    if (initFilterOptions) {
      const vendorOptions = selectedVendors.map(vendor => vendor.value)
      const projectOptions = initFilterOptions.projects.filter(project => vendorOptions.includes(project.label))
      const resourceGroupOptions = selectedVendors.map(vendor => vendor.value).includes(Vendor.AZURE)
        ? initFilterOptions.resourceGroups
        : []
      const serviceOptions = initFilterOptions.services.filter(service => vendorOptions.includes(service.label))
      const regionOptions = initFilterOptions.regions.filter(region => vendorOptions.includes(region.label))
      setFilterOptions({
        projects: projectOptions,
        resourceGroups: resourceGroupOptions,
        services: serviceOptions,
        regions: regionOptions
      })
    } else {
      setFilterOptions(initFilterOptions)
    }
  }, [initFilterOptions, selectedVendors])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const chosenPreset = savedFilterPreset.find(preset => preset.name === selectedPreset?.value)
      setSelectedVendors(chosenPreset?.vendors ?? vendorOptions)
      setSelectedProjects(chosenPreset?.projects ?? [])
      setSelectedResourceGroups(chosenPreset?.resourceGroups ?? [])
      setSelectedServices(chosenPreset?.services ?? [])
      setSelectedRegions(chosenPreset?.regions ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPreset])

  const savePresets = (name: string) => {
    const filter: SustainabilityFilterPreset = {
      name: name,
      vendors: selectedVendors,
      projects: selectedProjects.map(project => ({
        value: project.value,
        label: project.label
      })),
      resourceGroups: selectedResourceGroups.map(resourceGroup => ({
        value: resourceGroup.value,
        label: resourceGroup.label
      })),
      services: selectedServices.map(service => ({
        value: service.value,
        label: service.label
      })),
      regions: selectedRegions.map(region => ({
        value: region.value,
        label: region.label
      }))
    }
    saveSustainabilityFilterPreset(filter, createCancelToken().token)
      .then(savedPreset => {
        setSavedFilterPreset(
          [...savedFilterPreset].filter(preset => savedPreset.name !== preset.name).concat(savedPreset)
        )
        setSelectedPreset({
          value: savedPreset.name,
          label: savedPreset.name
        })
        setMessage({
          message: t('filters.presetSaveSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const deletePreset = (name: string) => {
    deleteSustainabilityFilterPreset(name, createCancelToken().token)
      .then(() => {
        setSelectedPreset(null)
        setSavedFilterPreset([...savedFilterPreset].filter(preset => preset.name !== name))
        setMessage({
          message: t('filters.presetDeleteSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const options: FilterMenu[] = [
    {
      type: FilterType.VENDORS,
      value: selectedVendors,
      options: vendorOptions,
      onChange: setSelectedVendors
    },
    {
      type: FilterType.PROJECTS,
      value: selectedProjects,
      options: filterOptions?.projects ?? [],
      onChange: setSelectedProjects
    }
  ]

  const selectedOptions: SelectedFilterOption[] = [
    {
      type: FilterType.VENDORS,
      options: selectedVendors.length > 0 ? selectedVendors : vendorOptions,
      clickHandler: setSelectedVendors
    },
    {
      type: FilterType.PROJECTS,
      options: selectedProjects,
      clickHandler: setSelectedProjects
    }
  ]

  if (authInfo.azureIntegration) {
    options.push({
      type: FilterType.RESOURCE_GROUPS,
      value: selectedResourceGroups,
      options: filterOptions?.resourceGroups ?? [],
      onChange: setSelectedResourceGroups
    })

    selectedOptions.push({
      type: FilterType.RESOURCE_GROUPS,
      options: selectedResourceGroups,
      clickHandler: setSelectedResourceGroups
    })
  }

  options.push(
    {
      type: FilterType.REGIONS,
      value: selectedRegions,
      options: filterOptions?.regions ?? [],
      onChange: setSelectedRegions
    },
    {
      type: FilterType.SERVICES,
      value: selectedServices,
      options: filterOptions?.services ?? [],
      onChange: setSelectedServices
    }
  )

  selectedOptions.push(
    {
      type: FilterType.REGIONS,
      options: selectedRegions,
      clickHandler: setSelectedRegions
    },
    {
      type: FilterType.SERVICES,
      options: selectedServices,
      clickHandler: setSelectedServices
    }
  )

  const timeframeOptions = [
    {
      clickHandler: () => setSelectedTimeframe(TimeframeOption.LAST_YEAR),
      checked: selectedTimeframe === TimeframeOption.LAST_YEAR,
      value: t(`common.timeframeOptions.${TimeframeOption.LAST_YEAR}`)
    },
    {
      clickHandler: () => setSelectedTimeframe(TimeframeOption.CURRENT_YEAR),
      checked: selectedTimeframe === TimeframeOption.CURRENT_YEAR,
      value: t(`common.timeframeOptions.${TimeframeOption.CURRENT_YEAR}`)
    }
  ]

  return (
    <Filters
      filterMenuOptions={options}
      selectedOptions={selectedOptions}
      saveAction={savePresets}
      deleteAction={deletePreset}
      selectedPreset={selectedPreset}
      setSelectedPreset={setSelectedPreset}
      savedPresets={savedFilterPreset.map(preset => preset.name)}
      loading={loading}
      topLevelAction={
        <TimeframeFilter timeframeOptions={timeframeOptions} additionalInfo={t('sustainability.reportingInfo')} />
      }
    />
  )
}
