import styled from 'styled-components'
import tw from 'twin.macro'
import { Vendor } from '../../utils/vendors'

interface TextProps {
  hideOnWideScreen?: boolean
  capitalizeAll?: boolean
  lowercase?: boolean
}

export const CapitalizedText = styled.div<TextProps>`
  ${({ capitalizeAll, lowercase }) =>
    lowercase ? tw`lowercase` : capitalizeAll ? tw`capitalize` : tw`first-letter:capitalize`}
  ${({ hideOnWideScreen }) => hideOnWideScreen && tw`lg:hidden`}
`

export const GrayText = styled(CapitalizedText)`
  ${tw`text-gray-200`}
  ${({ lowercase }) => lowercase && tw`lowercase`}
  strong {
    ${tw`text-gray-50 font-semibold`}
  }
`

export const WhiteText = styled.div`
  ${tw`text-gray-50`}
`

export const GradientText = styled.span`
  ${tw`bg-clip-text text-transparent bg-gradient-to-b`}
`

export const Heading = styled(CapitalizedText)`
  ${tw`w-full text-gray-50 font-semibold tracking-wide text-112 md:text-125`}
`

export const Heading2 = styled(CapitalizedText)`
  ${tw`w-full text-gray-50 font-semibold tracking-wide text-100 md:text-112`}
`

export const Heading3 = styled(CapitalizedText)`
  ${tw`w-full text-gray-50 font-bold tracking-wide md:text-100`}
`

export const BoldLink = styled.a<{ capitalize?: boolean }>`
  ${tw`cursor-pointer text-center text-90 text-primary-500 font-semibold hover:text-primary-300`}
  ${({ capitalize }) => capitalize && tw`first-letter:capitalize`}
`

interface VendorNameProps {
  vendor: Vendor
  blank?: boolean
  fontSize?: 'default' | 'larger' | 'large'
}

export const VendorName = styled.div<VendorNameProps>`
  ${tw`font-semibold`}
  ${({ fontSize }) => (fontSize === 'larger' ? tw`text-112` : fontSize === 'large' ? tw`text-125` : tw`text-100`)}
  ${({ vendor, blank }) =>
    vendor === Vendor.AWS
      ? blank
        ? tw`text-gray-50`
        : tw`text-brand-aws`
      : vendor === Vendor.AZURE
        ? blank
          ? tw`text-gray-50 lowercase first-letter:capitalize`
          : tw`text-brand-azure`
        : blank
          ? tw`text-gray-50`
          : tw`text-brand-gcp`}
`
