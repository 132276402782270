import { client } from '../client'
import { CostAnomalyLimits } from '../notifications'
import { Vendor } from '../../utils/vendors'
import { CancelToken } from 'axios'
import { PermittedVendorProject } from '../admin/users'
import { CurrencyId } from '../../utils/Currency'

export const getUserSettings = async (cancelToken: CancelToken): Promise<UserSettings> => {
  return await client.get('settings/user-settings', { cancelToken }).then(resp => resp.data)
}

export const getUserPermittedProjects = async (
  vendor: Vendor,
  cancelToken: CancelToken
): Promise<PermittedVendorProject[]> => {
  return await client
    .get<PermittedVendorProject[]>(`settings/permitted-projects/${vendor.toLowerCase()}`, { cancelToken })
    .then(resp => resp.data)
}

export const changeUserPreferredCurrency = async (
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<UserSettings> => {
  return await client.post('settings/currency', currency, { cancelToken }).then(resp => resp.data)
}

export const getIntegrationStateMessages = async (cancelToken: CancelToken): Promise<TenantIntegrationStates> => {
  return await client.get('/integration-states', { cancelToken }).then(resp => resp.data)
}

export interface UserSettings {
  currency: CurrencyId
  defaultCostAnomalyLimits: CostAnomalyLimits
  visibleVendors: Vendor[]
}

export interface TenantIntegrationStates {
  aws: AwsIntegrationStates[] | null
  azure: AzureIntegrationStates[] | null
  gcp: GcpIntegrationStates[] | null
}

interface AwsIntegrationStates {
  masterAccount: string
  configAccount: string
  securityAccount: string
  lastOrganizationIntegrationAt: Date | null
  lastResourceIntegrationAt: Date | null
  lastBillingIntegrationAt: Date | null
  lastRecommendationIntegrationAt: Date | null
  lastComplianceIntegrationAt: Date | null
  lastUsageIntegrationAt: Date | null
}

interface AzureIntegrationStates {
  azureTenantId: string
  lastOrganizationIntegrationAt: Date | null
  lastBillingIntegrationAt: Date | null
  lastRecommendationIntegrationAt: Date | null
  lastComplianceIntegrationAt: Date | null
  lastUsageIntegrationAt: Date | null
}

interface GcpIntegrationStates {
  organizationId: string
  billingAccount: string
  lastOrganizationIntegrationAt: Date | null
  lastBillingIntegrationAt: Date | null
  lastRecommendationIntegrationAt: Date | null
  lastComplianceIntegrationAt: Date | null
  lastUsageIntegrationAt: Date | null
}

export const hasIntegrationErrors = (
  states: AwsIntegrationStates[] | AzureIntegrationStates[] | GcpIntegrationStates[] | null
) =>
  states?.some(
    i =>
      !i.lastOrganizationIntegrationAt ||
      ('lastResourceIntegrationAt' in i && !i.lastResourceIntegrationAt) ||
      !i.lastBillingIntegrationAt ||
      !i.lastComplianceIntegrationAt ||
      !i.lastRecommendationIntegrationAt ||
      !i.lastUsageIntegrationAt
  ) || false
