import React, { useContext, useEffect, useRef, useState } from 'react'
import { FilterOption } from '../shared/filters/FilterSelect'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { ModalContext, ModalState } from '../../state/context/ModalContext'
import { Vendor } from '../../utils/vendors'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'
import { useTranslation } from 'react-i18next'
import { FilterMenu, Filters, FilterType, SelectedFilterOption } from '../shared/filters/Filters'
import {
  ComplianceFilterData,
  ComplianceFilterPreset,
  deleteComplianceFilterPreset,
  getComplianceFilterData,
  getComplianceFilterPresets,
  saveComplianceFilterPreset
} from '../../api/compliance/filters'

interface Props {
  selectedVendors: FilterOption[]
  setSelectedVendors: (selectedVendors: FilterOption[]) => void
  selectedProjects: FilterOption[]
  setSelectedProjects: (selectedProjects: FilterOption[]) => void
  selectedResourceGroups: FilterOption[]
  setSelectedResourceGroups: (selectedResourceGroups: FilterOption[]) => void
  selectedStandards: FilterOption[]
  setSelectedStandards: (selectedStandards: FilterOption[]) => void
}

export const ComplianceFilters = ({
  selectedVendors,
  setSelectedVendors,
  selectedProjects,
  setSelectedProjects,
  selectedResourceGroups,
  setSelectedResourceGroups,
  selectedStandards,
  setSelectedStandards
}: Props) => {
  const { authInfo, userSettings } = useContext(UserInfoContext)
  const { setModal } = useContext<ModalState>(ModalContext)
  const { setMessage } = useContext<MessageState>(MessageContext)

  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(true)
  const [initFilterData, setInitFilterData] = useState<ComplianceFilterData | null>(null)
  const [filterOptions, setFilterOptions] = useState<ComplianceFilterData | null>(null)
  const [savedFilterPresets, setSavedFilterPresets] = useState<ComplianceFilterPreset[]>([])
  const [selectedPreset, setSelectedPreset] = useState<FilterOption | null>(null)
  const isInitialMount = useRef<boolean>(true)
  const vendorOptions = userSettings.visibleVendors.map(v => ({
    label: t(`vendors.${v}.short`),
    value: v
  }))

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    Promise.all([
      getComplianceFilterData(
        selectedProjects.map(project => project.value),
        cancelToken.token
      ).then(filterData => {
        setFilterOptions(filterData)
        setInitFilterData(filterData)
      }),
      getComplianceFilterPresets(cancelToken.token).then(setSavedFilterPresets)
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
    }
  }, [selectedProjects, createCancelToken, handleError])

  useEffect(() => {
    if (selectedVendors.length > 0 && initFilterData) {
      const projectOptions = initFilterData.projectOptions.filter(project =>
        selectedVendors.map(vendor => vendor.value).includes(project.label)
      )
      const resourceGroupOptions = selectedVendors.map(vendor => vendor.value).includes(Vendor.AZURE)
        ? initFilterData.resourceGroupOptions
        : []
      const standardOptions = initFilterData.standardOptions.filter(standard =>
        selectedVendors.map(vendor => vendor.value).includes(standard.label)
      )
      setFilterOptions({
        projectOptions,
        resourceGroupOptions,
        standardOptions
      })
    } else {
      setFilterOptions(initFilterData)
    }
  }, [initFilterData, selectedVendors, setSelectedVendors])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const chosenPreset = savedFilterPresets.find(filter => filter.name === selectedPreset?.value)
      setSelectedVendors(chosenPreset?.vendors ?? vendorOptions)
      setSelectedProjects(chosenPreset?.projects ?? [])
      setSelectedResourceGroups(chosenPreset?.resourceGroups ?? [])
      setSelectedStandards(chosenPreset?.standards ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPreset])

  const saveFilter = (name: string) => {
    const filter: ComplianceFilterPreset = {
      name: name,
      vendors: selectedVendors,
      projects: selectedProjects.map(entry => ({
        value: entry.value,
        label: entry.label,
        count: entry.count
      })),
      resourceGroups: selectedResourceGroups.map(entry => ({
        value: entry.value,
        label: entry.label,
        count: entry.count
      })),
      standards: selectedStandards.map(entry => ({
        value: entry.value,
        label: entry.label,
        count: entry.count
      }))
    }
    saveComplianceFilterPreset(filter, createCancelToken().token)
      .then(savedPreset => {
        setSavedFilterPresets(
          [...savedFilterPresets].filter(preset => preset.name !== savedPreset.name).concat(savedPreset)
        )
        setSelectedPreset({
          label: savedPreset.name,
          value: savedPreset.name
        })
        setMessage({
          message: t('filters.presetSaveSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const deleteFilter = (name: string) => {
    deleteComplianceFilterPreset(name, createCancelToken().token)
      .then(() => {
        setSelectedPreset(null)
        setSavedFilterPresets([...savedFilterPresets].filter(preset => preset.name !== name))
        setMessage({
          message: t('filters.presetDeleteSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
      .finally(() => setModal(null))
  }

  const selects: FilterMenu[] = [
    {
      type: FilterType.VENDORS,
      value: selectedVendors,
      options: vendorOptions,
      onChange: setSelectedVendors
    },
    {
      type: FilterType.PROJECTS,
      value: selectedProjects,
      options: filterOptions?.projectOptions ?? [],
      onChange: setSelectedProjects
    }
  ]

  const tags: SelectedFilterOption[] = [
    {
      type: FilterType.VENDORS,
      options: selectedVendors.length > 0 ? selectedVendors : vendorOptions,
      clickHandler: setSelectedVendors
    },
    {
      type: FilterType.PROJECTS,
      options: selectedProjects,
      clickHandler: setSelectedProjects
    }
  ]

  if (authInfo.azureIntegration) {
    selects.push({
      type: FilterType.RESOURCE_GROUPS,
      value: selectedResourceGroups,
      options: filterOptions?.resourceGroupOptions ?? [],
      onChange: setSelectedResourceGroups
    })
    tags.push({
      type: FilterType.RESOURCE_GROUPS,
      options: selectedResourceGroups,
      clickHandler: setSelectedResourceGroups
    })
  }

  selects.push({
    type: FilterType.STANDARDS,
    value: selectedStandards,
    options: filterOptions?.standardOptions ?? [],
    onChange: setSelectedStandards
  })

  tags.push({
    type: FilterType.STANDARDS,
    options: selectedStandards,
    clickHandler: setSelectedStandards
  })

  return (
    <Filters
      filterMenuOptions={selects}
      selectedOptions={tags}
      saveAction={saveFilter}
      deleteAction={deleteFilter}
      selectedPreset={selectedPreset}
      setSelectedPreset={setSelectedPreset}
      savedPresets={savedFilterPresets.map(it => it.name)}
      loading={loading}
    />
  )
}
