import React, { useContext, useEffect, useRef, useState } from 'react'
import { ComplianceChartData, getComplianceSummaryChartResults } from '../../../api/compliance/common'
import styled from 'styled-components'
import tw from 'twin.macro'
import bombDetonator from '../../../assets/svg/objects/flat-colored/bomb-detonator.svg'
import firstPlace from '../../../assets/svg/objects/flat-colored/trophy.svg'
import error from '../../../assets/svg/objects/flat-colored/error.svg'
import { useTranslation } from 'react-i18next'
import { ComplianceHistoryChart } from './ComplianceHistoryChart'
import { DataBlock } from '../../shared/containers/DataBlock'
import { Vendor } from '../../../utils/vendors'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import { Heading } from '../../shared/TextComponents'
import { Timescale } from '../../../utils/classes'
import {
  AwsAzureComplianceSummaryTabData,
  GcpComplianceSummaryTabData,
  getComplianceSummaryTabData
} from '../../../api/compliance/tabs'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { UserInfoContext } from '../../../state/context/UserInfoContext'

interface SummaryTabProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedStandards: string[]
}

export const ComplianceSummaryTab = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedStandards
}: SummaryTabProps) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [awsAzureData, setAwsAzureData] = useState<AwsAzureComplianceSummaryTabData | null>(null)
  const [gcpData, setGcpData] = useState<GcpComplianceSummaryTabData | null>(null)
  const [chartData, setChartData] = useState<ComplianceChartData[]>([])
  const [chartTimescale, setChartTimescale] = useState<Timescale>(Timescale.MONTH)
  const [loading, setLoading] = useState(true)
  const [chartLoading, setChartLoading] = useState(true)
  const selectedTimescale = useRef(chartTimescale)

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (selectedTimescale.current !== chartTimescale) {
      setChartData([])
      selectedTimescale.current = chartTimescale
      getComplianceSummaryChartResults(
        selectedVendors,
        selectedProjects,
        selectedResourceGroups,
        selectedStandards,
        chartTimescale,
        cancelToken.token
      ).then(resp => {
        setChartData(resp)
        setChartLoading(false)
      })
    } else {
      Promise.all([
        getComplianceSummaryTabData(
          selectedVendors,
          selectedProjects,
          selectedResourceGroups,
          selectedStandards,
          cancelToken.token
        ).then(data => {
          setAwsAzureData(data.awsAzure)
          setGcpData(data.gcp)
          setLoading(false)
        }),
        getComplianceSummaryChartResults(
          selectedVendors,
          selectedProjects,
          selectedResourceGroups,
          selectedStandards,
          chartTimescale,
          cancelToken.token
        ).then(resp => {
          setChartData(resp)
          setChartLoading(false)
        })
      ]).catch(handleError)
    }

    return () => {
      setLoading(false)
      setChartLoading(false)
      cancelToken.cancel()
    }
  }, [
    selectedVendors,
    selectedProjects,
    selectedResourceGroups,
    selectedStandards,
    chartTimescale,
    createCancelToken,
    handleError
  ])

  return (
    <Wrapper>
      <ComplianceHistoryChart
        selectedVendors={selectedVendors}
        chartData={chartData}
        chartTimescale={chartTimescale}
        setChartTimescale={setChartTimescale}
        loading={chartLoading}
      />
      <VendorSummaries
        selectedVendors={selectedVendors}
        loading={loading}
        awsAzureData={awsAzureData}
        gcpData={gcpData}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${tw`flex flex-col w-full gap-4 lg:gap-16 transition-all ease-in-out`}
`

interface VendorSummariesProps {
  selectedVendors: Vendor[]
  loading: boolean
  awsAzureData: AwsAzureComplianceSummaryTabData | null
  gcpData: GcpComplianceSummaryTabData | null
}

const VendorSummaries = ({ selectedVendors, loading, awsAzureData, gcpData }: VendorSummariesProps) => {
  const { userSettings } = useContext(UserInfoContext)
  const showAwsAzure =
    userSettings.visibleVendors.includes(Vendor.AWS) || userSettings.visibleVendors.includes(Vendor.AZURE)
  const showGcp = userSettings.visibleVendors.includes(Vendor.GCP)
  const columnCount = showAwsAzure && showGcp ? 2 : 1

  return (
    <SummariesContainer columns={columnCount}>
      {showAwsAzure && (
        <AwsAzureColumn
          selectedVendors={selectedVendors.filter(vendor => vendor !== Vendor.GCP)}
          loading={loading}
          data={awsAzureData}
        />
      )}
      {showGcp && <GcpColumn loading={loading} data={gcpData} />}
    </SummariesContainer>
  )
}

interface SummariesContainerProps {
  columns: 1 | 2
}

const SummariesContainer = styled.div<SummariesContainerProps>`
  ${tw`grid w-full gap-8 lg:gap-12`}
  ${({ columns }) => (columns === 2 ? tw`lg:grid-cols-2` : tw`lg:grid-cols-1`)}
`

interface ColumnProps {
  loading: boolean
  data: AwsAzureComplianceSummaryTabData | null
  selectedVendors: Vendor[]
}

const AwsAzureColumn = ({ loading, data, selectedVendors }: ColumnProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const vendors = userSettings.visibleVendors
    .filter(vendor => vendor !== Vendor.GCP)
    .filter(v => (selectedVendors.filter(s => s !== Vendor.GCP).length > 0 ? selectedVendors.includes(v) : true))
  const heading = vendors.map(vendor => t(`vendors.${vendor}.short`)).join(' & ')

  const subHeading =
    (vendors.includes(Vendor.AWS) && vendors.includes(Vendor.AZURE)) || !vendors.length
      ? `${data?.accountCount} ${t('vendors.AWS.projectPhrase', { count: data?.accountCount })} & ${data?.subCount} ${t(
          'vendors.AZURE.projectPhrase',
          { count: data?.subCount }
        )}`
      : vendors.includes(Vendor.AWS)
        ? `${data?.accountCount} ${t('vendors.AWS.projectPhrase', { count: data?.accountCount })}`
        : `${data?.subCount} ${t('vendors.AZURE.projectPhrase', { count: data?.subCount })}`

  const projectPhrases = selectedVendors.length
    ? selectedVendors
        .map(vendor =>
          t('compliance.tabs.summary.dataBlockSubHeading.project', {
            context: vendor
          })
        )
        .join(' / ')
    : t('compliance.tabs.summary.dataBlockSubHeading.project')

  return (
    <ColumnWrapper>
      <div>
        <Heading>{heading}</Heading>
        <SubHeading>{data ? subHeading : t('error.noData')}</SubHeading>
      </div>
      <DataBlocksWrapper>
        <DataBlock
          loading={loading}
          headerIcon={<CustomIcon iconType={IconType.FLAT} path={bombDetonator} styles={'w-9 h-9'} />}
          heading={t('compliance.tabs.summary.leastCompliant')}
          subHeading={projectPhrases}
          data={data?.leastCompliant ?? []}
          scoreBarChart={true}
          id={`qa-compliance-summary-tab-${Vendor.AWS}-${Vendor.AZURE}-least-compliant`}
        />
        <DataBlock
          loading={loading}
          headerIcon={<CustomIcon iconType={IconType.FLAT} path={firstPlace} styles={'w-9 h-9'} />}
          heading={t('compliance.tabs.summary.mostCompliant')}
          subHeading={projectPhrases}
          data={data?.mostCompliant ?? []}
          scoreBarChart={true}
          id={`qa-compliance-summary-tab-${Vendor.AWS}-${Vendor.AZURE}-most-compliant`}
        />
        <DataBlock
          loading={loading}
          headerIcon={<CustomIcon iconType={IconType.FLAT} path={error} styles={'w-9 h-9'} />}
          heading={t('compliance.tabs.summary.mostFailedChecks')}
          subHeading={`${projectPhrases}`}
          data={data?.mostFailedChecks ?? []}
          id={`qa-compliance-summary-tab-${Vendor.AWS}-${Vendor.AZURE}-most-failed-checks`}
        />
      </DataBlocksWrapper>
    </ColumnWrapper>
  )
}

interface GcpColumnProps {
  loading: boolean
  data: GcpComplianceSummaryTabData | null
}

const GcpColumn = ({ loading, data }: GcpColumnProps) => {
  const { t } = useTranslation()
  return (
    <ColumnWrapper>
      <div>
        <Heading>{t('vendors.GCP.short')}</Heading>
        <SubHeading>
          {data
            ? `${data.projectCount} ${t(`vendors.GCP.projectPhrase`, {
                count: data.projectCount
              })}`
            : t('error.noData')}
        </SubHeading>
      </div>
      <DataBlock
        id={`qa-compliance-summary-tab-${Vendor.GCP}-most-findings`}
        headerIcon={<CustomIcon iconType={IconType.FLAT} path={error} styles={'w-9 h-9'} />}
        heading={t('compliance.tabs.summary.mostFindings')}
        subHeading={`${t('common.by')} ${t('vendors.GCP.projectPhrase_one')}`}
        data={data?.mostFindings ?? []}
        loading={loading}
      />
    </ColumnWrapper>
  )
}

const ColumnWrapper = styled.div`
  ${tw`flex flex-col h-fit gap-6`}
`

const DataBlocksWrapper = styled.div`
  ${tw`flex flex-wrap w-full gap-8`}
`

const SubHeading = styled.div`
  ${tw`first-letter:capitalize text-gray-200`}
`
