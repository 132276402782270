import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Dashboard } from './Dashboard'
import { UserInfoContext } from '../state/context/UserInfoContext'

interface Props {
  children: JSX.Element
  exact: boolean
  path: string
}

export const PrivateRoute = ({ children, exact, path }: Props) => {
  const { authInfo } = useContext(UserInfoContext)

  return (
    <Route
      exact={exact}
      path={path}
      render={() => (authInfo.isLoggedIn ? <Dashboard>{children}</Dashboard> : <Redirect to={'/login'} />)}
    />
  )
}
