import React, { useContext, useEffect, useState } from 'react'
import { initI18n } from './utils/i18n'
import Routes from './layout/Routes'
import { FullScreenLoading } from './components/shared/Loading'
import { useCancelToken } from './api/client'
import { UserInfoContext } from './state/context/UserInfoContext'
import { getAuthInfo } from './api/auth'
import { useHistory } from 'react-router-dom'
import { GlobalMessage } from './global-message/GlobalMessage'
import { getIntegrationStateMessages, getUserSettings } from './api/settings/profile'

initI18n('en')
const App = () => {
  const { createCancelToken } = useCancelToken()
  const { authInfo, setAuthInfo, setUserSettings, setIntegrationStates } = useContext(UserInfoContext)
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    getAuthInfo(cancelToken.token)
      .then(setAuthInfo)
      .catch(console.error)
      .finally(() => setLoading(false))

    return () => createCancelToken().cancel()
  }, [createCancelToken, history, setAuthInfo])

  useEffect(() => {
    const cancelToken = createCancelToken()
    Promise.all([
      getIntegrationStateMessages(cancelToken.token).then(setIntegrationStates),
      getUserSettings(cancelToken.token).then(setUserSettings)
    ]).catch(console.error)
  }, [authInfo, createCancelToken, setUserSettings, setIntegrationStates])

  if (loading) return <FullScreenLoading />

  return (
    <>
      <Routes />
      <GlobalMessage />
    </>
  )
}

export default App
