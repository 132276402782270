import React, { useContext } from 'react'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { useTranslation } from 'react-i18next'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import { GrayText, WhiteText } from '../../shared/TextComponents'
import { SubNavContentWrapper } from '../../../layout/SubNav'
import { NestedPageHeader, NestedPageHeaderActions, NestedPageHeaderTitles } from '../../shared/NestedPageHeader'
import { Link } from 'react-router-dom'
import userIcon from '../../../assets/svg/objects/user.svg'
import { NavbarLinkId } from '../../../layout/navigation/Sidenav'
import signOut from '../../../assets/svg/actions/sign-out.svg'
import { changeUserPreferredCurrency } from '../../../api/settings/profile'
import { Currencies, CurrencyFlag, CurrencyId, getCurrency } from '../../../utils/Currency'
import { getCostAnomalyNotifications } from '../../../api/notifications'
import { UserPermissions } from './UserPermissions'
import { useCancelToken } from '../../../api/client'
import { NotificationsContext } from '../../../state/context/NotificationsContext'
import { useErrorHandling } from '../../../hooks/handleError'
import { UserRole } from '../../../api/auth'
import { ADMIN_ROLES } from '../../admin/roleConstants'
import { MessageContext, MessageType } from '../../../state/context/MessageContext'
import { RadioButton } from '../../shared/buttons/RadioButton'

export const UserProfile = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)

  return (
    <>
      <NestedPageHeader>
        <NestedPageHeaderTitles
          children={
            <div className={'flex gap-4 items-center'}>
              <CustomIcon iconType={IconType.VECTOR} path={userIcon} alt={'user-icon'} styles={'w-8 h-8 bg-gray-50'} />
              <div>
                <div className={'text-gray-50 font-semibold text-125'}>
                  {`${authInfo.firstName} ${authInfo.lastName}`}
                </div>
                <GrayText>{authInfo.tenant.name}</GrayText>
              </div>
            </div>
          }
        />
        <NestedPageHeaderActions>
          <Link id={NavbarLinkId.LOGOUT} to={'/logout'} className={'flex items-center gap-2 group'}>
            <CustomIcon
              path={signOut}
              styles={'w-5 h-5 bg-gray-200 group-hover:bg-gray-50'}
              alt={t('common.signOut')}
            />
            <GrayText className={'text-80 group-hover:text-gray-50'}>{t('common.signOut')}</GrayText>
          </Link>
        </NestedPageHeaderActions>
      </NestedPageHeader>
      <SubNavContentWrapper divider={true}>
        <UserPermissions />
        <UserCurrency />
      </SubNavContentWrapper>
    </>
  )
}

const UserCurrency = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const { userSettings, setUserSettings } = useContext(UserInfoContext)
  const { setMessage } = useContext(MessageContext)
  const { createCancelToken } = useCancelToken()
  const { setNotifications } = useContext(NotificationsContext)
  const handleError = useErrorHandling()

  const handleUserCurrencyChange = (selectedCurrency: CurrencyId) => {
    const cancelToken = createCancelToken()
    changeUserPreferredCurrency(selectedCurrency, cancelToken.token)
      .then(resp => {
        setUserSettings(resp)
        authInfo.roles.some(role => ADMIN_ROLES.includes(role) || role === UserRole.COSTS_USER) &&
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('settings.currencyChangeSuccess')
        })
      })
      .catch(handleError)
  }

  return (
    <div className={'flex flex-col gap-2'}>
      <WhiteText className={'first-letter:capitalize font-semibold text-90'}>{t('settings.currencyHeading')}</WhiteText>
      <GrayText>{t('settings.supportedCurrencies')}</GrayText>
      <div className={'flex flex-col gap-2.5 max-w-max'}>
        {Currencies.map(currencyId => (
          <RadioButton
            key={currencyId}
            type={'container'}
            onChange={() => handleUserCurrencyChange(currencyId)}
            checked={userSettings.currency === currencyId}
            label={
              <div className={'flex gap-3 items-center'}>
                <CurrencyFlag currencyId={currencyId} size={8} />
                <div className={'text-80'}>
                  <WhiteText className={'font-semibold'}>{t(`common.currency.${currencyId}`)}</WhiteText>
                  <GrayText>{getCurrency(currencyId).symbolLong}</GrayText>
                </div>
              </div>
            }
          />
        ))}
      </div>
    </div>
  )
}
