import React, { ReactElement, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import Select from 'react-select'
import { DataContainer } from '../components/shared/containers/DataContainer'
import { CapitalizedText } from '../components/shared/TextComponents'
import {
  selectControlStyles,
  SelectDropdownIndicator,
  SelectIndicatorSeparator,
  selectMenuListStyles,
  selectOptionStyles
} from '../components/shared/filters/ReactSelectStyles'
import { Alert } from '../components/shared/indicators/Alert'
import { PingType } from '../components/shared/tabs/Tab'
import { Ping } from '../components/shared/indicators/Ping'

interface SubNavLayoutProps {
  links: SubNavLink[]
  children: React.ReactNode
}

export const SubNavLayout = ({ links, children }: SubNavLayoutProps) => {
  return (
    <DataContainer looseSpacing={false} subNav={true}>
      <SubNav links={links} />
      <div className={'w-full'}>{children}</div>
    </DataContainer>
  )
}

export interface SubNavLink {
  label: string
  to: string
  highlight?: PingType | false
}

interface SubNavProps {
  links: SubNavLink[]
}

export const SubNav = ({ links }: SubNavProps): ReactElement => {
  const history = useHistory()
  const location = useLocation()
  const matchedLink = links.find(link => link.to === location.pathname) || null // support for browser BACK navigation
  const [selectedOption, setSelectedOption] = useState(matchedLink)

  const handleMobileMenuClick = (option: any) => {
    setSelectedOption(option)
    if (option && option.to) {
      history.push(option.to)
    }
  }

  return (
    <>
      <SubNavMobileMenu>
        <Select
          unstyled
          isSearchable={false}
          value={selectedOption}
          options={links}
          onChange={handleMobileMenuClick}
          classNames={{
            control: () => selectControlStyles,
            singleValue: () => 'first-letter:capitalize',
            option: () => selectOptionStyles + ' first-letter:capitalize',
            menuList: () => selectMenuListStyles
          }}
          components={{
            IndicatorSeparator: SelectIndicatorSeparator,
            DropdownIndicator: SelectDropdownIndicator
          }}
        />
      </SubNavMobileMenu>
      <SubNavWrapper>
        {links.map(link => (
          <StyledNavLink key={link.label} to={link.to} activeClassName={'subnav-active-class'}>
            <CapitalizedText>{link.label}</CapitalizedText>
            {link.highlight === PingType.ERROR ? (
              <div className={'-mt-2.5'}>
                <Alert animate={true} size={5} />
              </div>
            ) : link.highlight === PingType.POINT ? (
              <Ping dimmed={false} />
            ) : null}
          </StyledNavLink>
        ))}
      </SubNavWrapper>
    </>
  )
}

const SubNavMobileMenu = styled.div`
  ${tw`p-4 border-b border-gray-500 lg:hidden`}
`

const SubNavWrapper = styled.nav`
  ${tw`hidden sticky top-0 flex-col gap-2 py-6 border-r border-gray-500 w-50 h-screen lg:flex`}
`

const StyledNavLink = styled(NavLink)`
  ${tw`px-6 py-4 cursor-pointer border-l-4 border-gray-600 flex capitalize hover:border-primary-500/50 hover:text-gray-50`}
  &.subnav-active-class {
    ${tw`border-primary-500 text-primary-500`}
  }
`

interface SubNavContentWrapperProps {
  divider?: boolean
}

export const SubNavContentWrapper = styled.div<SubNavContentWrapperProps>`
  ${tw`flex flex-col w-full gap-5 px-10 py-8 min-h-[70vh] lg:min-h-[85vh]`}
  ${({ divider }) => (divider ? tw`divide-y divide-gray-500 gap-0` : tw`gap-0 lg:gap-8`)}
  > div {
    ${({ divider }) => divider && tw`py-8 first:pt-0 last:pb-0`}
  }
`
