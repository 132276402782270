import React, { useContext, useEffect } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import homeIcon from '../../assets/svg/sidebar/home-icon.svg'
import costsIcon from '../../assets/svg/sidebar/costs-icon.svg'
import complianceIcon from '../../assets/svg/sidebar/compliance-icon.svg'
import ticketsIcon from '../../assets/svg/sidebar/tickets-icon.svg'
import sustainabilityIcon from '../../assets/svg/sidebar/sustainability-icon.svg'
import systemAdminIcon from '../../assets/svg/sidebar/system-admin-icon.svg'
import infraIcon from '../../assets/svg/sidebar/infra-icon.svg'
import adminIcon from '../../assets/svg/sidebar/admin-icon.svg'
import optimizationIcon from '../../assets/svg/sidebar/optimization-icon.svg'
import notificationsIcon from '../../assets/svg/sidebar/notifications-icon.svg'
import userSettingsIcon from '../../assets/svg/sidebar/user-settings-icon.svg'
import masterAdminIcon from '../../assets/svg/sidebar/master-admin-icon.svg'
import spotterLogo from '../../assets/svg/brand/spotter-logo-glow.svg'
import { featuresFlags } from '../../state/featuresFlags'
import tw from 'twin.macro'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { AuthInfo, UserRole } from '../../api/auth'
import { BackgroundOverlay } from '../../components/shared/layout/BackgroundOverlay'
import { CustomIcon, IconType } from '../../components/shared/CustomIcon'
import { ADMIN_ROLES } from '../../components/admin/roleConstants'
import { TenantMenu } from './TenantMenu'
import { CapitalizedText, WhiteText } from '../../components/shared/TextComponents'
import { Notifications } from '../../components/notifications/Notifications'
import { toast } from 'react-toastify'
import { NotificationsContext } from '../../state/context/NotificationsContext'
import { NotificationState } from '../../api/notifications'
import { ModalContext } from '../../state/context/ModalContext'
import { firstLogin } from '../../state/storage'
import { SideDrawer } from '../../components/shared/layout/SideDrawer'
import closeIcon from '../../assets/svg/actions/collapse-burger-menu.svg'
import { NavbarContext } from '../../state/context/NavbarContext'
import { PingType } from '../../components/shared/tabs/Tab'
import { hasIntegrationErrors, TenantIntegrationStates } from '../../api/settings/profile'
import { Alert } from '../../components/shared/indicators/Alert'
import { Ping } from '../../components/shared/indicators/Ping'

export enum NavbarLinkId {
  HOME = 'qa-navbar-home',
  COSTS = 'qa-navbar-costs',
  OPTIMIZATION = 'qa-navbar-optimization',
  COMPLIANCE = 'qa-navbar-compliance',
  TICKETS = 'qa-navbar-tickets',
  INFRA = 'qa-navbar-infra',
  SUSTAINABILITY = 'qa-navbar-sustainability',
  NOTIFICATIONS = 'qa-navbar-notifications',
  ADMIN = 'qa-navbar-admin',
  SYSTEM_ADMIN = 'qa-navbar-sysadmin',
  MASTER_ADMIN = 'qa-navbar-master-admin',
  SETTINGS = 'qa-navbar-settings',
  LOGOUT = 'qa-navbar-logout'
}

interface NavbarItem {
  text: string
  path: string
  icon: string
  id: NavbarLinkId
  display: boolean
  hasFilters: boolean
  ping?: PingType | false
}

export const Sidenav = () => {
  const { t } = useTranslation()
  const { notificationsOpen } = useContext(NotificationsContext)
  const location = useLocation()
  const history = useHistory()
  const { setModal } = useContext(ModalContext)
  const { authInfo, integrationStates } = useContext(UserInfoContext)
  const { navOpen, setNavOpen } = useContext(NavbarContext)
  const isSysAdmin = authInfo.roles.includes(UserRole.SYSTEM_ADMIN)
  const hasIntegration = authInfo.awsIntegration || authInfo.azureIntegration || authInfo.gcpIntegration
  const isAdmin = authInfo.roles.some(role => ADMIN_ROLES.includes(role))
  const navbarLinks = getNavLinks(authInfo, integrationStates, t)

  useEffect(() => {
    !location.pathname.includes('/integrations') &&
      !location.pathname.includes('/profile') &&
      !hasIntegration &&
      isAdmin &&
      history.push('/admin/integrations')

    if (location.pathname.includes('/profile') && !hasIntegration) setModal(null)
  }, [location, hasIntegration, isAdmin, history])

  return (
    <>
      <LinksContainer className={'no-scrollbar'}>
        {navbarLinks.map(link => (
          <SidenavLink key={link.text} link={link} />
        ))}
      </LinksContainer>
      <div className={'flex xl:hidden'}>
        <SideDrawer
          id={'sidenav-drawer'}
          drawerOpen={navOpen}
          setDrawerOpen={setNavOpen}
          paddingX={3}
          transitionStyles={'origin-left left-0'}
          content={
            <div className={'flex flex-col divide-y divide-gray-500'}>
              {isSysAdmin && (
                <div className={'flex w-full items-center justify-between px-4 pb-4 gap-8'}>
                  <TenantMenu />
                  <CustomIcon
                    path={closeIcon}
                    styles={'w-5 h-5 bg-gray-100 hover:bg-gray-50'}
                    onClick={() => setNavOpen(false)}
                    tooltipText={t('sideNav.closeMenu')}
                    tooltipStyles={'w-max -ml-13'}
                  />
                </div>
              )}
              <LogoContainer className={'py-4'}>
                <Link to={'/home'}>
                  <CustomIcon
                    id={'qa-navbar-logo'}
                    iconType={IconType.VECTOR}
                    path={spotterLogo}
                    styles={'bg-primary-500 w-36 h-28'}
                  />
                </Link>
                {!isSysAdmin && <WhiteText className={'text-center text-gray-50'}>{authInfo.tenant.name}</WhiteText>}
              </LogoContainer>
              <LinksContainer className={'items-start no-scrollbar'}>
                {navbarLinks.map(link => (
                  <SidenavLink key={link.text} link={link} />
                ))}
              </LinksContainer>
            </div>
          }
        />
      </div>
      {featuresFlags.notifications && authInfo.notificationsAccess && <Notifications />}
      <BackgroundOverlay visible={notificationsOpen || navOpen} />
    </>
  )
}

const LinksContainer = styled.div`
  ${tw`flex flex-col py-8 transition-all ease-in-out overflow-y-auto`}
  #qa-navbar-admin {
    ${tw`mt-6`}
  }
`

const LogoContainer = styled.div`
  ${tw`flex flex-col gap-2`}
  a {
    ${tw`flex flex-col items-center`}
  }
`

interface SideBarLinkProps {
  link: NavbarItem
}

const SidenavLink = ({ link }: SideBarLinkProps) => {
  const { notificationsOpen, setNotificationsOpen } = useContext(NotificationsContext)
  const { notifications } = useContext(NotificationsContext)
  const { setNavOpen } = useContext(NavbarContext)
  const unreadNotifications = notifications?.filter(
    n => n.notificationState === NotificationState.UNREAD || n.notificationState === NotificationState.NEW
  ).length

  if (link.id === NavbarLinkId.NOTIFICATIONS)
    return (
      <div
        onClick={() => {
          setNavOpen(false)
          setNotificationsOpen(!notificationsOpen)
          !firstLogin() && toast.dismiss()
        }}
        className={`${linkStyles} ${notificationsOpen ? 'text-gray-50 border-primary-500' : 'border-transparent hover:ml-1 hover:text-gray-50/70 hover:border-primary-500/60'}`}
        key={link.text}
        id={link.id}
      >
        <CustomIcon
          id={'sidebar-link-icon'}
          path={link.icon}
          styles={`bg-gray-300 w-6 h-6 ${notificationsOpen && 'bg-gray-50'}`}
        />
        <div className={'flex items-center gap-1.5 xl:max-2xl:flex-col'}>
          <CapitalizedText className={'min-w-max xl:max-2xl:text-80 text-90'}>{link.text}</CapitalizedText>
          {unreadNotifications > 0 && (
            <div
              className={
                'flex items-center justify-center tracking-tight leading-none rounded-full bg-primary-500 text-gray-50 font-semibold text-75 min-w-4 h-4 px-1.5 2xl:text-80'
              }
            >
              {unreadNotifications}
            </div>
          )}
        </div>
      </div>
    )

  return (
    <LinkContainer
      onFocus={() => {
        setNavOpen(false)
        setNotificationsOpen(false)
      }}
      className={linkStyles}
      activeClassName={notificationsOpen ? 'selected-link-dimmed' : 'selected-link'}
      key={link.text}
      to={link.path}
      id={link.id}
    >
      <CustomIcon id={'sidebar-link-icon'} path={link.icon} styles={'bg-gray-300 w-6 h-6'} />
      <CapitalizedText className={'min-w-max xl:max-2xl:text-80 text-90'}>
        {link.text}
        {link.ping === PingType.ERROR ? (
          <div className={'inline-flex absolute -mt-1'}>
            <Alert size={5} animate={true} />
          </div>
        ) : link.ping === PingType.POINT ? (
          <Ping dimmed={false} />
        ) : null}
      </CapitalizedText>
    </LinkContainer>
  )
}

const linkStyles =
  'flex px-3 py-4 justify-center items-center text-gray-300 transition-all ease-in-out duration-200 cursor-pointer gap-3 xl:px-5 xl:border-r-4 xl:flex-col xl:gap-0.5 2xl:flex-row 2xl:justify-start 2xl:gap-3'

const LinkContainer = styled(NavLink)`
  ${tw`border-transparent`}
  &.selected-link,
  &.selected-link-dimmed {
    ${tw`text-gray-50 ml-1 border-primary-500`}
    #sidebar-link-icon {
      ${tw`bg-gray-50`}
    }
  }

  &:hover:not(.selected-link):not(.selected-link-dimmed) {
    ${tw`text-gray-50/70 ml-1 border-primary-500/60`}
    #sidebar-link-icon {
      ${tw`bg-gray-50/70`}
    }
  }
`

export const getNavLinks = (authInfo: AuthInfo, integrationStates: TenantIntegrationStates, t: TFunction) => {
  const navbarLinks: NavbarItem[] = [
    {
      text: t('sideNav.home'),
      path: '/home',
      icon: homeIcon,
      id: NavbarLinkId.HOME,
      display: featuresFlags.home,
      hasFilters: false
    },
    {
      text: t('sideNav.costs'),
      path: '/costs',
      icon: costsIcon,
      id: NavbarLinkId.COSTS,
      display: featuresFlags.costs && authInfo.costsAccess,
      hasFilters: true
    },
    {
      text: t('sideNav.optimization'),
      path: '/optimization',
      icon: optimizationIcon,
      id: NavbarLinkId.OPTIMIZATION,
      display: featuresFlags.optimization && authInfo.optimizationAccess,
      hasFilters: true
    },
    {
      text: t('sideNav.compliance'),
      path: '/compliance',
      icon: complianceIcon,
      id: NavbarLinkId.COMPLIANCE,
      display: featuresFlags.compliance && authInfo.complianceAccess,
      hasFilters: true
    },
    {
      text: t('sideNav.tickets'),
      path: '/tickets',
      icon: ticketsIcon,
      id: NavbarLinkId.TICKETS,
      display: featuresFlags.tickets && !!authInfo.freshIntegration && authInfo.ticketAccess,
      hasFilters: false
    },
    {
      text: 'infra',
      path: '/infra',
      icon: infraIcon,
      id: NavbarLinkId.INFRA,
      display: featuresFlags.infra && authInfo.infraAccess,
      hasFilters: true
    },
    {
      text: t('sideNav.sustainability'),
      path: '/sustainability',
      icon: sustainabilityIcon,
      id: NavbarLinkId.SUSTAINABILITY,
      display: featuresFlags.sustainability && authInfo.sustainabilityAccess,
      hasFilters: true
    },
    {
      text: t('sideNav.notifications'),
      path: '',
      icon: notificationsIcon,
      id: NavbarLinkId.NOTIFICATIONS,
      display: featuresFlags.notifications && authInfo.notificationsAccess,
      hasFilters: false
    }
  ]

  authInfo.roles.some(role => ADMIN_ROLES.includes(role)) &&
    navbarLinks.push({
      text: t('sideNav.admin'),
      path: '/admin',
      icon: adminIcon,
      id: NavbarLinkId.ADMIN,
      display: featuresFlags.admin,
      hasFilters: false,
      ping:
        (hasIntegrationErrors(integrationStates.aws) ||
          hasIntegrationErrors(integrationStates.azure) ||
          hasIntegrationErrors(integrationStates.gcp)) &&
        PingType.ERROR
    })

  authInfo.roles.some(role => role === UserRole.SYSTEM_ADMIN || role === UserRole.MASTER_ADMIN) &&
    navbarLinks.push({
      text: t('sideNav.systemAdmin'),
      path: '/system-admin',
      icon: systemAdminIcon,
      id: NavbarLinkId.SYSTEM_ADMIN,
      display: featuresFlags.systemAdmin,
      hasFilters: false
    })

  authInfo.roles.includes(UserRole.MASTER_ADMIN) &&
    navbarLinks.push({
      text: t('sideNav.masterAdmin'),
      path: '/master-admin',
      icon: masterAdminIcon,
      id: NavbarLinkId.MASTER_ADMIN,
      display: featuresFlags.masterAdmin,
      hasFilters: false
    })

  navbarLinks.push({
    text: t('sideNav.settings'),
    path: '/settings',
    icon: userSettingsIcon,
    id: NavbarLinkId.SETTINGS,
    display: true,
    hasFilters: false
  })

  return navbarLinks.filter(link => link.display)
}
