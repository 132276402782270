import { Vendor } from '../../../../../utils/vendors'
import { OrganizationUser, RoleType, updateUserRoles } from '../../../../../api/admin/users'
import { UserRole } from '../../../../../api/auth'
import { RadioButton } from '../../../../shared/buttons/RadioButton'
import React, { useContext } from 'react'
import { UserInfoContext } from '../../../../../state/context/UserInfoContext'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageState, MessageType } from '../../../../../state/context/MessageContext'
import { useCancelToken } from '../../../../../api/client'
import { useErrorHandling } from '../../../../../hooks/handleError'
import { AWS_ROLES, AZURE_ROLES, GCP_ROLES, VISIBILITY_ROLES } from '../../../roleConstants'
import { GrayText, Heading } from '../../../../shared/TextComponents'
import { AdminTabContent } from '../../../adminStyles'

interface RolesProps {
  vendor?: Vendor
  vendorUserRole?: UserRole
  user: OrganizationUser
  setUser: (user: OrganizationUser) => void
}

export const Roles = ({ vendor, vendorUserRole, user, setUser }: RolesProps) => {
  const handleError = useErrorHandling()
  const { authInfo } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { setMessage } = useContext<MessageState>(MessageContext)

  const adminRole =
    vendor === Vendor.AWS
      ? UserRole.AWS_ADMIN
      : vendor === Vendor.AZURE
        ? UserRole.AZURE_ADMIN
        : vendor === Vendor.GCP
          ? UserRole.GCP_ADMIN
          : UserRole.ADMIN

  const updateRoles = (userId: string, roleType: RoleType, roles: UserRole[]) => {
    const cancelToken = createCancelToken().token
    updateUserRoles(roleType, userId, roles, cancelToken)
      .then(resp => {
        setUser(resp)
        setMessage({
          message: t('admin.tabs.rolesAndAccessRights.roleChangeSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
  }

  const roleButtonProps =
    vendor && vendorUserRole
      ? [
          {
            checked:
              !user.roles.includes(UserRole.ADMIN) &&
              !user.roles.some(role =>
                vendor === Vendor.AWS
                  ? AWS_ROLES.includes(role)
                  : vendor === Vendor.AZURE
                    ? AZURE_ROLES.includes(role)
                    : GCP_ROLES.includes(role)
              ),
            disabled: user.roles.includes(UserRole.ADMIN),
            tooltipText: t('admin.tabs.rolesAndAccessRights.tooltipText.disableAdmin', {
              targetPhrase: t('admin.tabs.rolesAndAccessRights.tooltipText.disableAccess')
            }),
            onChange: () => updateRoles(user.id, vendor, []),
            label: t('admin.tabs.rolesAndAccessRights.noAccess'),
            description: t('admin.tabs.rolesAndAccessRights.noAccessDescription', {
              projectPhrase: t(`vendors.${vendor}.projectPhrase_other`)
            })
          },
          {
            checked: !!user.roles.find(role => role === adminRole),
            disabled: user.roles.includes(UserRole.ADMIN),
            tooltipText: t('admin.tabs.rolesAndAccessRights.tooltipText.disableAdmin', {
              targetPhrase: t('admin.tabs.rolesAndAccessRights.tooltipText.switchRole')
            }),
            onChange: () => updateRoles(user.id, vendor, [adminRole]),
            label: t(`admin.roles.${adminRole}`),
            description: t(`admin.roles.${adminRole}_description`)
          },
          {
            checked: user.roles.includes(vendorUserRole),
            disabled: user.roles.includes(UserRole.ADMIN),
            tooltipText: t('admin.tabs.rolesAndAccessRights.tooltipText.disableAdmin', {
              targetPhrase: t('admin.tabs.rolesAndAccessRights.tooltipText.switchRole')
            }),
            onChange: () => updateRoles(user.id, vendor, [vendorUserRole]),
            label: t(`admin.roles.${vendorUserRole}`),
            description: t(`admin.roles.${vendorUserRole}_description`)
          }
        ]
      : [
          {
            checked: user.roles.includes(UserRole.ADMIN),
            onChange: () => {
              updateRoles(user.id, 'admin', [UserRole.ADMIN])
            },
            label: t('admin.roles.ADMIN'),
            description: t(`admin.roles.ADMIN_description`)
          },
          {
            checked: !user.roles.includes(UserRole.ADMIN),
            onChange: () => updateRoles(user.id, 'admin', []),
            label: t('admin.tabs.rolesAndAccessRights.user'),
            description: t('admin.tabs.rolesAndAccessRights.userRoleDescription')
          }
        ]

  if (user.id === authInfo.id)
    return (
      <>
        {user.roles
          .filter(role => role === adminRole)
          .map(role => (
            <AdminTabContent key={role}>
              <Heading>{t(`admin.roles.${role}`)}</Heading>
              <div>
                <GrayText>
                  {t('admin.tabs.rolesAndAccessRights.selfRoleEdit.description', {
                    vendor: vendor ? t(`vendors.${vendor}.short`) : ''
                  })}
                </GrayText>
                <GrayText>
                  {t('admin.tabs.rolesAndAccessRights.selfRoleEdit.instruction', {
                    vendorAdmin: vendor
                      ? t('admin.tabs.rolesAndAccessRights.selfRoleEdit.or') + ' ' + t(`admin.roles.${role}`) + 's'
                      : ''
                  })}
                </GrayText>
              </div>
            </AdminTabContent>
          ))}
      </>
    )

  return (
    <AdminTabContent>
      <Heading>
        {vendor
          ? t('admin.tabs.rolesAndAccessRights.vendorRole', {
              vendor: t(`vendors.${vendor}.short`)
            })
          : t('admin.roles.ADMIN')}
      </Heading>
      <div className={'flex flex-col gap-4'}>
        {roleButtonProps.map(
          (props, index) => (
            <RadioButton key={index} {...props} />
          ),
          []
        )}
        {user.roles.some(role => role === vendorUserRole) &&
          !user.roles.some(role => VISIBILITY_ROLES.includes(role)) && (
            <GrayText>{t('admin.tabs.rolesAndAccessRights.userVisibilityNote')}</GrayText>
          )}
      </div>
    </AdminTabContent>
  )
}
