import React, { useContext, useEffect, useState } from 'react'
import { InfraMap } from './InfraMap'
import { InfraFilters } from './InfraFilters'
import { FilterOption } from '../shared/filters/FilterSelect'
import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { InfraTabs } from './tabs/InfraTabs'
import { Vendor } from '../../utils/vendors'
import { useTranslation } from 'react-i18next'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { capitalizeFirstLetter } from '../../utils/formats'
import { ToastActionComponent } from '../../global-message/GlobalMessage'
import { ADMIN_ROLES } from '../admin/roleConstants'
import { useHistory } from 'react-router-dom'

export const Infra = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authInfo, userSettings, integrationStates } = useContext(UserInfoContext)
  const { setMessage } = useContext(MessageContext)
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>([])
  const [selectedRegions, setSelectedRegions] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedResources, setSelectedResources] = useState<FilterOption[]>([])

  if (!selectedVendors.length && userSettings.visibleVendors.length > 0)
    setSelectedVendors(
      userSettings.visibleVendors.map(v => ({
        label: t(`vendors.${v}.short`),
        value: v
      }))
    )

  useEffect(() => {
    integrationStates.aws?.find(
      i => i.lastOrganizationIntegrationAt === null || i.lastResourceIntegrationAt === null
    ) &&
      setMessage({
        type: MessageType.INFO,
        heading: t('integrationStates.vendorErrorHeading', {
          vendor: Vendor.AWS,
          label: t('vendors.AWS.resourcePhrase_other')
        }),
        message: t('integrationStates.awsConfigAccountError'),
        action: authInfo.roles.some(role => ADMIN_ROLES.includes(role)) ? (
          <ToastActionComponent
            label={t('admin.integrations.viewError')}
            onClick={() => history.push('/admin/integrations/aws')}
          />
        ) : undefined
      })
    integrationStates.azure?.find(i => i.lastOrganizationIntegrationAt === null) &&
      setMessage({
        type: MessageType.INFO,
        heading: t('integrationStates.vendorErrorHeading', {
          vendor: capitalizeFirstLetter(Vendor.AZURE.toLowerCase()),
          label: t('vendors.AZURE.resourcePhrase_other')
        }),
        message: t('integrationStates.azureTenantError'),
        action: authInfo.roles.some(role => ADMIN_ROLES.includes(role)) ? (
          <ToastActionComponent
            label={t('admin.integrations.viewError')}
            onClick={() => history.push('/admin/integrations/azure')}
          />
        ) : undefined
      })
    integrationStates.gcp?.find(i => i.lastOrganizationIntegrationAt === null) &&
      setMessage({
        type: MessageType.INFO,
        heading: t('integrationStates.vendorErrorHeading', {
          vendor: Vendor.AWS,
          label: t('vendors.GCP.resourcePhrase_other')
        }),
        message: t('integrationStates.gcpOrganizationError'),
        action: authInfo.roles.some(role => ADMIN_ROLES.includes(role)) ? (
          <ToastActionComponent
            label={t('admin.integrations.viewError')}
            onClick={() => history.push('/admin/integrations/gcp')}
          />
        ) : undefined
      })
  }, [integrationStates])

  return (
    <ViewWrapper>
      <InfraFilters
        selectedVendors={selectedVendors}
        selectedRegions={selectedRegions}
        selectedProjects={selectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        selectedResources={selectedResources}
        setSelectedVendors={setSelectedVendors}
        setSelectedRegions={setSelectedRegions}
        setSelectedProjects={setSelectedProjects}
        setSelectedResourceGroups={setSelectedResourceGroups}
        setSelectedResources={setSelectedResources}
      />
      <InfraMap
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedRegions={selectedRegions.map(r => r.value)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedResources={selectedResources.map(r => r.value)}
      />
      <InfraTabs
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedRegions={selectedRegions.map(r => r.value)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedResources={selectedResources.map(r => r.value)}
      />
    </ViewWrapper>
  )
}
